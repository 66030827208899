import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
/* RxJS */
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterSelected, UserFilters, UserGlobal } from '../../models/user';
import { ChartLoadingService } from '../../services/chart-loading.service';
import { GlobalFilterDbService } from '../../services/global-filter-db.service';
import { WellService } from '../../services/well.service';
import { ViewOptions } from '../../shared/constants/master-page.constants';
import * as util from '../../shared/utilities/util';
import * as globalFilterActions from '../../state/actions/global-filter.actions';
import * as fromFilter from '../../state/container-states/global-filter.state';
import { Sensor, Well } from '../../state/reducers/global-filter.reducer';
import { State } from 'src/app/state/container-states/app.state';

@Component({
  selector: 'app-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.scss']
})
export class GlobalFilterComponent implements OnChanges, OnDestroy {

  constructor(
    private store: Store<fromFilter.GlobalFilterState>,
    private filterDbService: GlobalFilterDbService,
    private chartLoadingService: ChartLoadingService,
    private wellService: WellService
  ) { }
  @Input() selectedTab: string;
  @Input()
  isOpen = false;
  @Output()
  hideDialog = new EventEmitter<boolean>();
  @Output()
  enableMenus = new EventEmitter();
  @Output()
  loaderCharts = new EventEmitter();
  unsubscribe$ = new Subject();
  userDetails: UserGlobal;
  wells: Well[] = [];
  sensors: Sensor[] = [];
  selectedWells: Well[] = [];
  selectedSensors: Sensor[] = [];
  currentSelectedWells: Well[] = []; //Holds the wells retured from list when well is toggled
  currentSelectedSensors: Sensor[] = []; //Holds the sensors retured from list when sensor is toggled
  // errorMessage: string ="";
  errorMessage$: Observable<string>;
  noDataWels: Well[] = [];
  showNoDataDailog = false;
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete

  ngOnChanges() {
    this.setSensorData();
  }

  /**
   * @method setSensorData
   * @description Sets subscriptions for state to local well/sensors data
   * @return {void}
   */
  setSensorData(): void {
    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        let wells = wellState.wells;

        this.noDataWels = this.wellService.getNoDataWells();
        this.isapiInprogress = wellState.apiInprogress;
        if (wells.length > 0 && !this.isapiInprogress) {
          // this.errorMessage = "";
          this.wells = util.sortByKeyFunc(wells, 'wellName');
        // this.wells = this.wellService.getRenamedWellSensors(this.wells);
        let allSensors = [];
        this.selectedWells = [];
        this.selectedSensors = [];
        this.wells.map((well) => {
          if (well.checked === true) {
            this.selectedWells.push(well);
            allSensors = allSensors.concat(well.sensors);
            this.sensors = allSensors.sort((a, b) => (a.wellName + '-' + a.sensorOriginalName).localeCompare((b.wellName + '-' + b.sensorOriginalName), undefined, {numeric: true}));
            this.selectedSensors = allSensors.filter((sensor) => {
              let sensorNickName = sensor.nickName ? sensor.nickName : sensor.displayName;
              sensor.combinedName = sensor.wellName + '-' + sensorNickName;
              return sensor.checked;
            });
          }
        });
        this.currentSelectedWells = this.selectedWells;
        this.currentSelectedSensors = this.selectedSensors;
        this.enableMenus.emit();
        this.loaderCharts.emit();
        }
      });
    // To Get user details
    this.getUserDetails();
    this.errorMessage$ = this.store.pipe(select(fromFilter.getWellsListError));
  }

  /**
   * @method onWellSelectionFromMap
   * @description Function called on selection of well from map , this function is called from map component.
   * @param {Well[]} selectedWell  Selected well from map
   * @return {void}
   */
  public onWellSelectionFromMap(selectedWell) {
    this.toggleWells([_.find(this.wells, (well) => well.uidWell === selectedWell.uidWell)]);
    this.applyFilter();
  }

  public isvalidWellData(selectedWell) {
    this.setSensorData(); // To load the state while navigating from map page
    if (_.find(this.wells, (well) => well.uidWell === selectedWell.uidWell)) {
      return true;
    }
    return false;
  }

  /**
   * @method toggleWells
   * @description Event handler for wells selections. Collects the sensors for selected wells
   * @param {Well[]} currentSelectedWells  Array of currently selected wells
   * @return {void}
   */
  toggleWells(currentSelectedWells: Well[]): void {
    this.currentSelectedWells = currentSelectedWells;
    if (this.wells.length < 1) {
      // Do nothing if no wells selected
      return;
    }

    this.selectedSensors = this.resetSensorSelection(
      this.selectedSensors,
      currentSelectedWells
    );
    const existingWells = this.wells.filter(
      (obj) => currentSelectedWells.indexOf(obj) > -1
    );
    this.sensors = this.getSensorsForSelectWells(existingWells, []);
  }

  /**
   * @method toggleSensors
   * @description Event handler for sensor selections.
   * @param {Well[]} sensors  List of selected sensors
   * @return {void}
   */
  toggleSensors(sensors: Sensor[]): void {
    this.selectedSensors = sensors;
    this.currentSelectedSensors = sensors;
  }

  applyFilter() {
    const tab = this.selectedTab === ViewOptions.oneMonth ? ViewOptions.oneMonth : ViewOptions.presTemp;

    if (tab === ViewOptions.presTemp) {
      this.chartLoadingService.showSpinner(tab);
    }

    if (this.currentSelectedWells.some((well) => well.checked === true)) {
      this.chartLoadingService.filterApplied = true;
    }

    const tempFilter: FilterSelected[] = [];
    for (const well of this.wells) {
      well.checked = false;
      for (const selectedWell of this.currentSelectedWells) {
        if (well.wellName === selectedWell.wellName) {
          well.checked = true;
          selectedWell.checked = true;
        }
      }
      for (const sensor of well.sensors) {
        sensor.checked = false;
        for (const selectedSensor of this.currentSelectedSensors) {

          if (sensor.sensorName === selectedSensor.sensorName &&
            well.wellName === selectedSensor.wellName) {
            sensor.checked = true;
            selectedSensor.checked = true;
            if (well.checked) {
              tempFilter.push({
                wellName: sensor.wellName,
                sensorName: sensor.sensorName,
                sensorType: sensor.unitType
              });
            }
          }
        }
      }
    }
    const selectedFilter: UserFilters = {
      userId: this.userDetails['email'],
      filters: tempFilter,
    };

    this.filterDbService.addFilterToDB(selectedFilter);
    this.updateSensorList(tempFilter);
    this.store.dispatch(new globalFilterActions.LoadWellsSuccess(this.wells));
    this.closeDialog();
  }
  updateSensorList(sensors) {
    if (localStorage.getItem('currentUser') === null) {
      return;
    }
    const user = JSON.parse(localStorage.getItem('currentUser')).email;
    let allSensors = JSON.parse(localStorage.getItem('allsensors'));
    if(allSensors == null){
      allSensors = [];
    }
    const check = allSensors.filter((value) => {
      return value.key === user;
    });
    if (check.length !== 0) {
      allSensors = allSensors.map((value) => {
        if (value.key === user) {
          value.sensors = sensors;
        }
        return value;
      });
    } else {
      allSensors.push({
        //key: JSON.parse(localStorage.getItem('currentUser')).email,
        key: JSON.parse(localStorage.getItem('currentUser')).email,
        sensors
      });
    }
    localStorage.setItem('allsensors', JSON.stringify(allSensors));
  }
  checkSensorSelection(selectedSensor: Sensor[], sensor: Sensor): boolean {
    return selectedSensor.some((tempSensor) => {
      return tempSensor.sensorName === sensor.sensorName
        && tempSensor.wellName === sensor.wellName;
    });
  }
  getUserDetails() {
    // TODO: store/fetch user data from NGRx state
    const userJobs = [];
    if (localStorage['currentUser']) {
      this.userDetails = JSON.parse(localStorage['currentUser']);
      this.userDetails.IWJob.map((job) => {
        userJobs.push({
          JobName: job.jobName,
          WellUid: job.wellUid,
          WellName: job.wellName
        });
      });
    }
  }

  closeDialog(): void {
    this.isOpen = false;
    this.hideDialog.emit(this.isOpen);
  }

  cancel() {
    this.closeDialog();
    this.selectedWells = [];
    this.selectedSensors = [];
    this.wells = [];
    this.sensors = [];
  }

  ngOnDestroy(): void {
    //To clear the existing wellswithNodata
    this.wellService.setNoDataWells([], []);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @method getSensorsForSelectWells
   * @description Sets local well/sensors data post change is state
   * @param {Well[]} selectedWell  Array of current selected wells
   * @param {Sensor[]} allSensors  Array of all the sensors
   * @return {Sensor[]} allSensors  Array of updated sensors for updated selected wells
   */
  getSensorsForSelectWells(
    selectedWell: Well[],
    allSensors: Sensor[]
  ): Sensor[] {
    if (selectedWell.length < 1) {
      return [];
    }
    for (const item of selectedWell) {
      allSensors = allSensors.concat(item.sensors);
    }
    allSensors = allSensors.sort((a, b) => (a.wellName + '-' + a.sensorOriginalName).localeCompare((b.wellName + '-' + b.sensorOriginalName), undefined, {numeric: true}));
    return allSensors;
  }

  /**
   * @method resetSensorSelection
   * @description Resets sensor selects based on updated well selection
   * @param {Sensor[]} selectedSensors  Array of current selected wells
   * @param {Well[]} currentSelectedWells  Array all the sensors
   * @return {Sensor[]} Array of updated sensor list
   */
  resetSensorSelection(
    selectedSensors: Sensor[],
    currentSelectedWells: Well[]
  ): Sensor[] {
    return selectedSensors.filter((sensor) => {
      // Set the selected sensors based on current well selection
      let ifExists = [];
      ifExists = currentSelectedWells.filter((well) => {
        // Check if sensor exists with the well in current well list
        return well.wellName === sensor.wellName;
      });
      return ifExists.length > 0;
    });
  }

  openNoWellDataDailog(){
    this.showNoDataDailog = true;
  }
  closeErrorDialog() {
    this.showNoDataDailog = false;
  }
}
