import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateRange } from '../../models/DateRange';
import { ChartLoadingService } from '../../services/chart-loading.service';
import { ExportService } from '../../services/export.service';
import { ViewOptions } from '../../shared/constants/master-page.constants';
import { addHoursByTimeZone, convertToNewTimeZone, subtractHoursByTimeZone } from '../../shared/utilities/timeZoneConverter';
import { State } from '../../state/container-states/app.state';
export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};
export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
          return formatDate(date, 'dd-MMM-yyyy', this.locale);
      }
  }

@Component({
  selector: 'app-date-calendar-picker',
  templateUrl: './date-calendar-picker.component.html',
  styleUrls: ['./date-calendar-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
]
})
export class DateCalendarPickerComponent implements OnInit {
  @Output() newDateRange = new EventEmitter<DateRange>();
  @Input() exportForm: boolean;
  @Input() historicalView: boolean;
  startDate: Date;
  endDate: Date;
  timeZone: string;
  disabled = false;
  unsubscribe$ = new Subject();

  constructor(private store: Store<State>,
              private element: ElementRef,
              private chartLoadingService: ChartLoadingService,
              private exportService: ExportService){}

  ngOnInit() {
    this.chartLoadingService.chartLoading[ViewOptions.presTemp]
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bool) => {
        if (!this.historicalView || !this.exportForm) {
          this.disabled = bool;
        }
      });

    this.exportService.resetDates
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bool) => {
        if (this.exportForm) {
          this.startDate = undefined;
          this.endDate = undefined;
          this.newDateRange.emit({
            start: this.startDate,
            end: this.endDate
          });
        }
      });

    this.store.select((state: State) => state.conversion.conversion.timeUnit)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((timeUnit) => {
        if (timeUnit) {
          if (!this.exportForm) {
            if (this.endDate) {
              this.endDate = convertToNewTimeZone(this.endDate, this.timeZone, timeUnit);
              if (this.historicalView) {
                this.endDate = undefined;
              }
            } else {
              if (!this.historicalView) {
                const now = new Date();
                this.endDate = addHoursByTimeZone(new Date(now.valueOf()), timeUnit);
              }
            }

            if (this.startDate) {
              this.startDate = convertToNewTimeZone(this.startDate, this.timeZone, timeUnit);
              if (this.historicalView) {
                this.startDate = undefined;
              }
            } else {
              if (!this.historicalView) {
                const now = new Date();
                const nowConverted = addHoursByTimeZone(new Date(now.valueOf()), timeUnit);
                this.startDate = new Date(nowConverted.setDate(nowConverted.getDate() - 7));
              }
            }
          }
          this.timeZone = timeUnit;
        }
      });
  }

  dateChecker() {
    const nowConverted = addHoursByTimeZone(new Date(), this.timeZone);

    if (this.startDate > nowConverted) {
      this.startDate = nowConverted;
    }

    if (this.endDate > nowConverted) {
      this.endDate = nowConverted;
    }

    if (this.startDate > this.endDate) {
      [this.startDate, this.endDate] = [this.endDate, this.startDate];
      this.startDate.setHours(0, 0, 0, 0);
      this.endDate.setHours(23, 59, 59, 999);
    }

    const originalStartDate = this.startDate
      ? new Date(this.startDate.getTime())
      : undefined;

    const originalEndDate = this.endDate
      ? new Date(this.endDate.getTime())
      : undefined;

    let newDateRange = {
      start: this.startDate
        ? subtractHoursByTimeZone(new Date(this.startDate.valueOf()), this.timeZone)
        : undefined,
      end: this.endDate
        ? subtractHoursByTimeZone(new Date(this.endDate.valueOf()), this.timeZone)
        : undefined
    };

    if (this.exportForm) {
      newDateRange = {
        start: this.startDate,
        end: this.endDate
      };
    }

    this.startDate = originalStartDate;
    this.endDate = originalEndDate;
    this.newDateRange.emit(newDateRange);
  }

  onEndDateChange(event) {
    this.endDate = new Date(event.value.setHours(23, 59, 59, 999));
    this.dateChecker();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
