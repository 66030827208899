import { DATA_TYPE, ITable } from 'jsstore';

export const userPrefTable: ITable = {
  name: 'UserPreferences',
  version: 10,
  columns: [{
    name: 'uid',
    primaryKey: true,
    autoIncrement: true,
   },
   {
     name: 'userId',
     dataType: DATA_TYPE.String,
   },
   {
    name: 'originalSensorLabel',
    notNull: true,
    dataType: DATA_TYPE.String,
   },
   {
    name: 'renamedSensorLabel',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'sensorWellName',
    dataType: DATA_TYPE.String,
    notNull: true,
   },
  ],
};

export const globalFilterTable: ITable = {
  name: 'GlobalFilter',
  columns: [{
    name: 'uid',
    primaryKey: true,
    autoIncrement: true,
   },
   {
     name: 'userId',
     dataType: DATA_TYPE.String,
   },
   {
    name: 'filters',
    dataType: DATA_TYPE.Array,
   }
  ],
};

export const unitConversionTable: ITable = {
  name: 'UnitConversions',
  columns: [{
    name: 'id',
    primaryKey: true,
    autoIncrement: true,
   },
   {
     name: 'userId',
     dataType: DATA_TYPE.String,
   },
   {
     name: 'temperatureUnit',
     dataType: DATA_TYPE.String,
   },
   {
    name: 'pressureUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'pitchangerateUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'gasrateUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'liquidrateUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'depthUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'timeUnit',
    dataType: DATA_TYPE.String,
   },
   {
    name: 'timeLabel',
    dataType: DATA_TYPE.String,
   }
  ],
};

export const chartSettingsTable: ITable = {
  name: 'ChartSettings',
  columns: [{
    name: 'id',
    primaryKey: true,
    autoIncrement: true,
  },
  {
    name: 'userId',
    dataType: DATA_TYPE.String,
  },
  {
    name: 'chartSettings',
    dataType: DATA_TYPE.Array,
  }
  ],
};
