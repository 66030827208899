import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { BaseService } from '../shared/services/indexdb/base.service';

@Injectable({
  providedIn: 'root'
})
export class ChartsSettingsService {
  userObjectFromLocal: any;

  constructor(private http: HttpClient, public dbService: BaseService) {
  }

  public getAxisType(): Observable<any> {
    return this.http.get('../../assets/data/sensorslist.json');
  }

  public insertDBChartsTable(value) { // to insert/update axis based on user
    let user = null;
    if (localStorage['currentUser']) {
      user = JSON.parse(localStorage['currentUser']);
      if (user) {
        this.dbService.connection
          .select({
            from: 'ChartSettings',
            where: {
              userId: user.email
            }
          })
          .then((res) => {
            if (res && res.length > 0) {
              this.dbService.connection
                .update({
                  in: 'ChartSettings',
                  set: {
                    value
                  },
                  where: {
                    userId: user.email
                  }
                })
                .then((result) => { })
                .catch((err) => console.log(err));
            } else {
              this.dbService.connection
                .insert({
                  into: 'ChartSettings',
                  return: true,
                  values: [{
                    userId: user.email,
                    value
                  }]
                })
                .then((result) => {
                  if (result) {
                  }
                })
                .catch((err) => console.log(err));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  public getChartsSelectSettings(email): Observable<any> {
    return from(
      this.dbService.connection.select({
        from: 'ChartSettings',
        where: {
          userId: email
        }
      }).catch((err) => console.log(err))
    );
  }

}
