import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAlert } from '../../models/alert';
import { WellService } from '../../services/well.service';
import { ViewOptions } from '../../shared/constants/master-page.constants';
import * as fromAlert from '../../state/container-states/alert.state';
import { SignalRService } from 'src/app/services/signal-r.service';
import { State } from 'src/app/state/container-states/app.state';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  @ViewChild('cmap') cmap;
  currentAlerts: any;
  viewer: any;
  extent: any;
  scene: any;
  wells: any;
  wellColors = {};
  convertColorCode = {
    'Cesium.Color.RED': Cesium.Color.RED,
    'Cesium.Color.CHARTREUSE': Cesium.Color.CHARTREUSE,
    'Cesium.Color.DARKGRAY': Cesium.Color.DARKGRAY
  };
  globalWells: any;
  unsubscribe$ = new Subject();
  viewOptions = ViewOptions;
  selectedWell: any;
  showDialog = false;
  @Input() menu: any;
  @Input() globalFilter: any;
  @Input() acknowledgedItems: any;
  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();
  public alerts: IAlert[] = [];
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete

  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private formStore: Store<fromAlert.AlertPageState>,
    private wellService: WellService,
    private store: Store<State>,
    private signalRService: SignalRService
  ) {
    //TODO: Replace this key from valid key from halliburton
    Cesium.Ion.defaultAccessToken = this.envConfig.cesiumIonDefaultToken;
  }

  ngOnInit() {
    //stop live readings signal r service if, map
    this.signalRService.stopConnection();
    localStorage.setItem('refreshFlag', '0');
    localStorage.setItem('component', 'map');
    this.globalWells = [];

    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {

        this.isapiInprogress = wellState.apiInprogress;

        /**
     * Get the wells with lat long information
     */

        if (!this.isapiInprogress) {
          this.wellService.getWells().subscribe((result) => {

            if (result) {
              this.updateWellInfo(result);

              //TODO: Move bingMapsImageryProviderUrl and bingMapsImageryProviderKey away from the environments file
              this.viewer = new Cesium.Viewer(this.cmap.nativeElement, {
                imageryProvider: new Cesium.BingMapsImageryProvider({
                  url: this.envConfig.bingMapsImageryProviderUrl,
                  key: this.envConfig.bingMapsImageryProviderKey,
                  mapStyle: Cesium.BingMapsStyle.AERIAL,
                  minimumLevel: 2
                }),
                mapProjection: new Cesium.WebMercatorProjection(),
                timeline: false,
                animation: false,
                baseLayerPicker: false,
                sceneMode: Cesium.SceneMode.SCENE2D,
                requestRenderMode: true,
                maximumRenderTimeChange: Infinity,
                navigationHelpButton: false,
                mapMode2D: Cesium.MapMode2D.ROTATE
              });
              const that = this;
              this.viewer.infoBox.frame.setAttribute('sandbox', 'allow-same-origin allow-popups allow-forms allow-scripts allow-top-navigation');
              this.addMapCoOrdinates();
              this.addClickEventsToMapInfo();
              this.viewer.homeButton.viewModel.command.beforeExecute.addEventListener((commandInfo) => {

                that.viewer.camera.flyTo({
                  destination: Cesium.Cartesian3.fromDegrees(10.5, 30.1614, 36733763.1580)
                });
                commandInfo.cancel = true;
              });
              this.addWellAlert();
            }
          });
        }
      })

    this.formStore
      .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
      .subscribe((alerts: IAlert[]) => {
        if (alerts) {
          this.currentAlerts = alerts;
          this.wellColors = {};
          /*
          * Set color of the well based on alarm
          *
          */
          _.map(alerts, (alert) => {
            const wellName = alert.WellName.toUpperCase();
            //state3: Alert added and triggered -> Red
            if (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) {
              this.wellColors[wellName] = 'Cesium.Color.RED';
              this.activeAlerts.emit({ alertTriggered: true, alertLength: alerts.length, alertList: alerts });
            } else { //state:2 & 4 Alert added and alert not triggered -> green
              if (!this.wellColors[wellName]) {
                this.wellColors[wellName] = 'Cesium.Color.CHARTREUSE';
              } else if (this.wellColors[wellName] !== 'Cesium.Color.RED') {
                this.wellColors[wellName] = 'Cesium.Color.CHARTREUSE';
              }
            }
          });
          this.addWellAlert();
        }
      });
  }

  closeErrorDialog() {
    this.showDialog = false;
  }
  /**
   *
   * @param result Function to format the well data to a simplied object
   */
  updateWellInfo(result) {
    const wells = result.response ? result.response.well : null;
    _.map(wells, (well) => {
      if (well.wellLocation) {
        this.globalWells.push({
          nameWell: well.name,
          uidWell: well.uid,
          field: well.field,
          licTime: well.dTimLicense,
          operator: well.operator,
          latitude: well.wellLocation && well.wellLocation[0].latitude ? well.wellLocation[0].latitude.value : null,
          longitude: well.wellLocation && well.wellLocation[0].longitude ? well.wellLocation[0].longitude.value : null,
          latUom: well.wellLocation && well.wellLocation[0].latitude ? well.wellLocation[0].latitude.uom : null,
          longUom: well.wellLocation && well.wellLocation[0].longitude ? well.wellLocation[0].longitude.uom : null,
        });
      }
    });
  }

  /**
   * Adding events to the map info box which shows up on click of map point
   */
  addClickEventsToMapInfo() {
    this.viewer.infoBox.frame.addEventListener('load', () => {
      //
      // Now that the description is loaded, register a click listener inside
      // the document of the iframe.
      //
      this.viewer.infoBox.viewModel.cameraClicked.addEventListener(() => {
        if (this.viewer.infoBox.viewModel.isCameraTracking)

          this.viewer.camera.flyTo({
            destination : Cesium.Cartesian3.fromDegrees(10.5, 30.1614, 36733763.1580)

      });
      });
      this.viewer.infoBox.frame.contentDocument.body.addEventListener('click', (e) => {
        //
        // The document body will be rewritten when the selectedEntity changes,
        // but this body listener will survive.  Now it must determine if it was
        // one of the clickable buttons.
        //
        if (e.target && e.target.className === 'shematics') {
          if (!this.globalFilter.isvalidWellData(this.selectedWell)) {
            this.showDialog = true;
            return;
           }

          this.updateGlobalSelection(this.selectedWell);
          this.toggleTab(this.viewOptions.newSchematic);
        } else if (e.target && e.target.className === 'charts') {
          if (!this.globalFilter.isvalidWellData(this.selectedWell)) {
            this.showDialog = true;
            return;
           }
          this.updateGlobalSelection(this.selectedWell);
          this.toggleTab(this.viewOptions.charts);
        }
      }, false);
    }, false);
  }

  /**
   * Toggles tab on redirecting to schematic or charts page
   * @param viewOptions
   */
  toggleTab(viewOptions) {
    this.menu.toggleTab(viewOptions);
  }

  /**
   * Updates the global selection of well
   * @param selectedWell
   */
  updateGlobalSelection(selectedWell) {
    this.globalFilter.onWellSelectionFromMap(selectedWell);
  }

  /**
   * Adding map points as well as design of map information box
   */
  addMapCoOrdinates() {
    const mapZoom = localStorage['mapZoom'] ? JSON.parse(localStorage['mapZoom']) : null;
    if (mapZoom && mapZoom.destroyFlag && mapZoom.destroyFlag === 1 && mapZoom.mode === 2) {
        this.viewer.camera.setView({
         destination: Cesium.Cartesian3.fromDegrees(mapZoom.longitude, mapZoom.latitude, mapZoom.zoomLevel),
         orientation: {
      heading: 0.0,
      pitch: -Cesium.Math.PI_OVER_TWO,
      roll: 0.0
    }
  });
        mapZoom.destroyFlag = 0;
        localStorage.setItem('mapZoom', JSON.stringify(mapZoom));

  }
  else{
    this.viewer.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(10.5, 30.1614, 36733763.1580),
      orientation: {
        heading: 0.0,
        pitch: -Cesium.Math.PI_OVER_TWO,
        roll: 0.0
      }
    });
  }

    _.map(this.globalWells, (well) => {
       const entity = this.viewer.entities.add({
          name: well.nameWell,
          selectedWell: well,
          description: '<h4><b>Well Name: </b>' + well.nameWell + '</br></br>' +
            '<b>Field Name: </b>' + well.field + '</br></br> ' +
            '<b>Latitude: </b>' + well.latitude + ' ' + well.latUom + '</br></br>' +
            '<b>Longitude: </b>' + well.longitude + ' ' + well.longUom + '</br></h4>' +
            '<button class="shematics" style="' +
            'color: #ffffff;font-family: UniversLTStd, sans-serif;font-size: 14px;font-weight: bold;font-stretch: normal;font-style: normal;letter-spacing: normal;' +
            'line-height: normal;text-align: center;padding: 8px 10px;font-size: 14px;border-radius: 8px;background-color: #cc0000;cursor: pointer;border: 1px solid #cc0000;">Schematics</button> ' +
            '<button class="charts" style="' +
            'color: #ffffff;font-family: UniversLTStd, sans-serif;font-size: 14px;font-weight: bold;font-stretch: normal;font-style: normal;letter-spacing: normal;' +
            'line-height: normal;text-align: center;padding: 8px 10px;font-size: 14px;border-radius: 8px;background-color: #cc0000;cursor: pointer;border: 1px solid #cc0000;">Charts</button> </br></br> ',
          position: Cesium.Cartesian3.fromDegrees(well.longitude , well.latitude),
          point : {
            color : (this.wellColors[well.nameWell]) ? this.convertColorCode[this.wellColors[well.nameWell]] : this.convertColorCode[Cesium.Color.DARKGRAY], //state:1 NO Alert added -> gray
            pixelSize : 10,
            outlineColor : Cesium.Color.BLACK,
            outlineWidth : 1
        },
          label : {
            show : false,
            showBackground : true,
            font : '18px sans-serif',
            backgroundColor : Cesium.Color.BLACK,
            horizontalOrigin : Cesium.HorizontalOrigin.LEFT,
            verticalOrigin : Cesium.VerticalOrigin.TOP,
            pixelOffset : new Cesium.Cartesian2(15, 0)
        }

        });
       const scene = this.viewer.scene;
       const handler1 = new Cesium.ScreenSpaceEventHandler(scene.canvas);
       handler1.setInputAction((movement) => {
          const pickedObject = scene.pick(movement.endPosition);
          if (Cesium.defined(pickedObject) && (pickedObject.id === entity)) {
            entity.label.text = entity.name;
            entity.label.show = true;
          } else {
            entity.label.show = false;
          }
          this.viewer.scene.requestRender();
          }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

       handler1.setInputAction((click) => {
            const pickedObject = scene.pick(click.position);
            if (Cesium.defined(pickedObject) && (pickedObject.id === entity)) {
              this.selectedWell = pickedObject.id.selectedWell;
              entity.label.text = entity.name;
              entity.label.show = true;
            }
            else
             {
                entity.label.show = false;
             }
       }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

     });
  }

 /*
 * Adding alerts on map
 */
  addWellAlert(){
    if (this.viewer && this.viewer.entities && this.viewer.entities.values){
      _.map(this.viewer.entities.values, (entity) => {
        entity.point.color = (this.wellColors[entity.name.toUpperCase()]) ? this.convertColorCode[this.wellColors[entity.name.toUpperCase()]] : this.convertColorCode[Cesium.Color.DARKGRAY];
      });
      this.viewer.scene.requestRender();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.viewer && this.viewer.camera) {
    const mapZoom = {
    zoomLevel: this.viewer.camera.positionCartographic.height,
    latitude: Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.latitude).toFixed(2),
    longitude: Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.longitude).toFixed(2),
    destroyFlag: 1,
    mode: this.viewer.scene.mode
   };
    localStorage.setItem('mapZoom', JSON.stringify(mapZoom));
  }
}

}
