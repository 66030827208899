import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()

export class ExportService {
    exportBegun: EventEmitter<{ jobName: string, fileId: string, started: boolean }> = new EventEmitter();
    downloadStatus: EventEmitter<{ downloading: boolean }> = new EventEmitter();
    transmitStatus: EventEmitter<{[key: string]: string}[]> = new EventEmitter();
    clearStatusProgress: EventEmitter<any> = new EventEmitter();
    public resetDates: Subject<boolean> = new Subject();
    constructor(@Inject('ENV_CONFIG') private envConfig: any,private http: HttpClient) {}

    prepareHeaders() {
        const { email } = JSON.parse(localStorage['currentUser']);
        return {
            headers: new HttpHeaders({
                'X-Email': email,
                'Content-Type': 'application/json'
            })
        };

    }

    public getStartEndTime(params: any) {
        let RtsComplexLogQueryList = [];
        params.logId.forEach((logId) => {
            RtsComplexLogQueryList.push({"Uid" : logId.multiLogId}); // passing multiple log id to get the date range
        });
        const body = {
            UidWell: params.uidWell,
            IndexType: 0,
            RtsComplexLogQueryList: RtsComplexLogQueryList

        };
        return this.http.post(this.envConfig.api.getMinMax, body);
    }

    public submitExportRequest(formPayload: any): Observable<any> {
      return this.http.post(
          this.envConfig.api.submitExport,
          formPayload,
          this.prepareHeaders()
      );
    }

    public getExportStatus(jobName: string): Observable<any> {
      if(jobName){
        return this.http.get(
            `${this.envConfig.api.export}${jobName}/Export`,
            this.prepareHeaders()
        );
      }
    }
    public cancelExport(jobName, fileId): Observable<any> {
        return this.http.delete(
            `${this.envConfig.api.export}${jobName}/Export/${fileId}`,
            this.prepareHeaders()
        );
    }

    public downloadFile(jobName: string, fileId: string, selectedWell: string) {
      const { waiToken } = JSON.parse(localStorage['currentUser']);
      if (jobName) {
        try{
        let url = `${this.envConfig.api.exportDownload}?fileKey=${fileId}&waiToken=${waiToken}&jobName=${jobName}`;
        
        return this.http.get(url, { responseType: 'blob', observe: 'response' });
          
        }catch(error){
          console.log(error);
        }

        // old Method
        // window.open(`${this.envConfig.api.exportDownload}?fileKey=${fileId}&waiToken=${waiToken}&jobName=${jobName}`, '_blank');
      }
    }
  }
