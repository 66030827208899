// tslint:disable:no-submodule-imports
import { Injectable } from '@angular/core';
import * as workerPath from 'file-loader?name=scripts/[name].[hash].js!jsstore/dist/jsstore.worker.ie.min.js';
import * as JsStore from 'jsstore';

@Injectable({
  providedIn: 'root',
})
export class DbConnectionService {
 // this will make sure that we are using one connection
 // otherwise multiple instance will be created and thus multiple
 // worker and that may create some problems
 static idbCon = new JsStore.Instance(new Worker(workerPath));
}
