import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ExportService } from 'src/app/services/export.service';
import { PressureAndTemperatureService } from 'src/app/services/pressure-temperature.service'
@Component({
  selector: 'app-export-status',
  templateUrl: './export-status.component.html',
  styleUrls: ['./export-status.component.scss']
})
export class ExportStatusComponent implements OnInit {
  @Input() fileId: string;
  @Input() jobName: string;
  @Input() fileReady: boolean;
  @Input() failed: boolean;
  @Input() isChartExport: boolean;
  @Input() initiated: boolean;
  @Input() selectedWell: string = $('.select-well-text').text();
  @Output() cancelExport: EventEmitter<any> = new EventEmitter();
  @Output() closeBar: EventEmitter<any> = new EventEmitter();
  unsubscribe$ = new Subject();
  @Input() cancelled: boolean;
  @Input() discard: boolean;
  public static displayWellName : string = "";
  barText: { [key: string]: any } = {
    checking: 'Your export is in progress. Meanwhile, you can navigate to other parts of the application.',
    cancelled: 'Your export has been successfully cancelled.',
    complete: (wellName: string) => `${wellName} has a pending export.  Please download or discard it before continuing.`,
    failed: 'Your requested failed. Please review your inputs.',
    discard: 'Your file has been successfully discarded.',
    initiated: 'Your export has initiated.'
  };
  constructor(
    private exportService: ExportService,
    private presTemp : PressureAndTemperatureService
  ) {

    if (!this.selectedWell.includes("Select a well to begin")) {
      ExportStatusComponent.displayWellName = this.selectedWell;
    }
    if (ExportStatusComponent.displayWellName !== "") {
      this.selectedWell = ExportStatusComponent.displayWellName;
    }
  }

  ngOnInit() {
  }

  handleCancel(isChartExport: boolean,discard?: boolean) {
    if(isChartExport) {
      if(!discard){
      this.presTemp.cancelExport();
      }
      else{
       this.presTemp.changeExportStatus('discard')
       this.presTemp.discardExport();
      }
    }
    else {
    this.exportService.cancelExport(this.jobName, this.fileId)
    .subscribe(response => {
      if (response) {
        if (discard) {
          this.discard = true;
        } else {
          this.cancelled = true;
        }
        this.cancelExport.emit();
      }
    });
  }
  }

  handleDownload(isChartExport) {
    if(!isChartExport){
      let fileName = `Export_${this.selectedWell.trim()}.asc`;
      // show the notification to the user saying that we are preparing your file
      this.exportService.downloadStatus.emit({
        downloading: true
      });
      this.exportService.downloadFile(this.jobName, this.fileId, this.selectedWell.trim())
        .subscribe(response => {
          // hide the notification
          var link = document.createElement('a');
          link.type = 'text/plain';
          link.href = window.URL.createObjectURL(response.body);
          link.download = fileName;
          link.click();
          this.exportService.downloadStatus.emit({           
            downloading: false
          });
      }); 
    }
    else{
    this.presTemp.downloadFile();
    }
    this.close(isChartExport);
  }

  close(isChartExport) {
    if(isChartExport){

      this.presTemp.changeExportStatus('close');
    }
    else{
    if (this.failed){
      this.handleFailure();
    } else{
      this.closeBar.emit();
    }
  }
  }

  handleFailure() {
    this.exportService.cancelExport(this.jobName, this.fileId)
      .subscribe(() => {
        this.closeBar.emit();
      });
  }
}
