import { Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
// Import Interface
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { IAlert } from '../../models/alert';
import { IMeasurementData } from '../../models/measurement';
import { ChartStoreService } from '../../services/chart.service';
// import service
import { PanelToggleService } from '../../services/panel-toggle.service';
import { SignalRService } from '../../services/signal-r.service';
import * as userActions from '../../state/actions/userActions';
import * as fromAlert from '../../state/container-states/alert.state';
import { State } from '../../state/container-states/app.state';
import { Well } from '../../state/reducers/global-filter.reducer';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';


@Component({
  selector: 'app-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.scss']
})
export class MeasurementComponent implements OnInit, OnDestroy, DoCheck  {
  // Data as input from parent
  @Input()
  measurementData: IMeasurementData;
  // Individual pressure temperature data
  @Input()
  displayData;

  @Input()
  color: string;
  @Input()
  isSchematic = false;
  @Input()
  showColor: 'hidden';

  @Input()
  isAlert = false;

  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();
  // display string
  public temperatureSensorLabel: string;
  // display string
  public pressureSensorLabel: string;
  // display string
  public editValue: string;
  // boolean for display
  public hideTemperature: boolean;
  // boolean for display
  hideSensor: boolean;
  // boolean for sections
  showPressure: boolean;
  showEditPress: boolean;
  showEditTemp: boolean;
  wells: Well[];
  sensorEditedName: string;
  unsubscribe$ = new Subject();
  liveReadingAlerts = {};
  wellInformation = {};
  showSensorNameAlert = false;
  currentAlerts: any;
  sensorEditedTruncated: string;
  mappedSensorColor: any;
  axisColor: string;
  showMultiple: boolean;
  indicator: any;
  fourGreenbar: boolean;
  threeGreenbar: boolean;
  twoGreenbar: boolean;
  red: boolean;
  showBatteryColor: any;
  currentVoltage: any;
  displayValue: any;
  sensorInfo: any[];

  constructor(
    private store: Store<State>,
    private panelToggleService: PanelToggleService,
    private formStore: Store<fromAlert.AlertPageState>,
    private chartStoreService: ChartStoreService,
    private alertService: AlertService,
    private alertState: Store<fromAlert.AlertPageState>,
    private signalRService: SignalRService,
  ) {
    this.sensorInfo = sensorInfo.sensorData;
  }

  ngDoCheck(): void {
    //  ngDoCheck is used to detect any changes in key value pair of an object
    //  Here used for checking any changes in the displaydata object
    if (this.displayData) {
      let unit;
      let name;
      this.sensorInfo.forEach((sensorList) => {
        if (this.displayData['sensorType'] === sensorList.sensorType) {
          name = sensorList.axisName;
          sensorList.sensorUnits.forEach((sensorUnit) => {
            if (sensorUnit.value == this.measurementData[this.displayData['sensorType'] + 'Unit']) {
              unit = sensorUnit.label;
            }
          });
        }
      });
      this.displayValue = {
        name: name && name.charAt(0).toUpperCase() + name.slice(1),
        value: this.displayData[this.displayData['sensorType']],
        unit
      };
      this.sensorEditedName = this.displayData.sensorLabel;
      const wellName = this.displayData.wellName ? this.displayData.wellName : this.getWellInformation(this.displayData)[0]['nameWell'];
      this.loadSensorEditedNames(this.displayData.sensorOriginalName, wellName);
      this.sensorEditedTruncated  = this.start_and_end(this.sensorEditedName);
    }
  }

  public ngOnInit(): void {

    this.formStore
    .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
    .subscribe((alerts: IAlert[]) => {
      if (alerts) {
        this.currentAlerts = alerts;
        this.liveReadingAlerts = {};
        _.map(alerts, (alert) => {
          const sensorName = alert.AlarmDetail.MnemonicName.toUpperCase();
          if (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) {
            this.liveReadingAlerts[alert.UidLog + sensorName] = '#cc0000';
            this.activeAlerts.emit({alertTriggered: true, alertLength: alerts.length, alertList: alerts});
          } else {
            if (!this.liveReadingAlerts[alert.UidLog + sensorName]) {
              this.liveReadingAlerts[alert.UidLog + sensorName] = 'green';
            } else if (this.liveReadingAlerts[alert.UidLog + sensorName] !== '#cc0000'){
              this.liveReadingAlerts[alert.UidLog + sensorName] = 'green';
            }
          }
        });
      }
    });

    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.wells = wellState.wells;

        this.wells.map((well) => {
          this.wellInformation[well.uid] = well.wellName;
        });
      });

    this.showPressure = false;
    this.showMultiple = false;

    this.panelToggleService.currentSelection.subscribe((payload) => {
      if (payload.typeSelected) {
        if (payload.typeSelected.toLocaleLowerCase() === 'multiple') {
          this.showMultiple = true;
        }
        else {
          this.showMultiple = false;
        }
        if (this.displayData) {
          this.showPressure = (this.displayData['sensorType'] === payload.typeSelected) ? true : false;
        }
      }
    });

    this.chartStoreService.currentColorMap.pipe(takeUntil(this.unsubscribe$)).subscribe((colorMap) => {
      this.mappedSensorColor =  colorMap;
      this.axisColor = this.getMappedColor(this.displayData.multiLogId + ' - ' + this['displayData'].sensorName);
    });

    this.alertState.select((state: State) => state['alertRules']['sensorList'])
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      result.forEach((well) => {
        if (well.nameWell === this.wellInformation[this.displayData.logid]) {
          const data = well.logData['data'];
          const index = this.getIndexForVoltage(well.logData['mnemonicList']);
          if (data.length > 0  && index !== -1) {
            this.showBatteryColor = true;
            let value = well.logData['data'][0][index];
            if (value === null || value === '') {
              value = well.logData['data'][1][index];
              if (value === null || value === undefined){
                this.showBatteryColor = false; // To do - validate battery data
              }
            }
            const isPercentage = well.logData['unitList'].length >= 1 ?  well.logData['unitList'][1] : null;
            this.batteryIndicator(value, isPercentage);
          }
        }
      });

    });
  }
 // Truncate function start here
  start_and_end(sensorEditedName) {
    if (sensorEditedName.length > 31 && sensorEditedName) {
     return sensorEditedName.substr(0, 12) + '...' + sensorEditedName.substr(sensorEditedName.length - 16, sensorEditedName.length);
    }
    return sensorEditedName;
  }
  //Function to get index for voltage data starts here
  getIndexForVoltage(mnemonicList) {
    //  const sensorName = this.alertService.getSensorNameForBattery(this.displayData['sensorOriginalName']);
     return mnemonicList.indexOf(this.displayData['batterySensorName']);
  }
   //Function to get index for voltage data ends here
  getMappedColor(wellSensorName) {
    const sensorColor = _.filter(this.mappedSensorColor, (item) => {
      return item.wellSensorName === wellSensorName;
    });
    if (sensorColor[0]) {
      return sensorColor[0]['sensorAxisColor'];
    } else {
      return 'white';
    }
  }



  /**
   * @name: getAlertReadings
   * @description: get live reading alerts
   * @param: {object} get list of object in display data
   * {array} liveReadingAlerts contains list of alerts
   */
  getAlertReadings(displayData, liveReadingAlerts) {
    return liveReadingAlerts[displayData.multiLogId + displayData.sensorName.toUpperCase()];
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * @name: editData
   * @description: method to open edit on click of button
   * @param: {string} unitSelected: selected value for edit
   * {string} typeOfLabel: denotes temp or pressure
   */
  public editData(typeOfLabel: string): void {
    this.sensorInfo.forEach((sensorList) => {
      if (sensorList.sensorType.toLowerCase() === typeOfLabel.toLowerCase()) {
        this.editValue = this.sensorEditedName;
      }
    });
    this.hideSensor = true;
  }

  /**
   * @name: updateValue
   * @description: Method to catch output from label and update value
   * @param: {string}: valueEntered: value emitted
   * {string}: labelChanged: type of label changed
   */
  public updateValue(valueEntered: string) {
    if (valueEntered && valueEntered !== '') {
      if (this.checkForDuplicateNames(this.displayData, valueEntered)) {
        this.showSensorNameAlert = true;
        return;
      }
      this.insertRenamedSensorInDB(
        valueEntered,
        this.displayData['sensorOriginalName'],
        this.getWellInformation(this.displayData)
      );
      this.displayData['sensorLabel'] = valueEntered;
      this.sensorEditedName = this.displayData.sensorLabel; // fix for rename and cancle
    }

    this.editValue = '';
    this.hideSensor = false;
    this.showEditPress = false;
    this.sensorEditedTruncated  = this.start_and_end(this.sensorEditedName);
    this.chartStoreService.changeRenameStatus(true);
  }

  closeErrorDialog() {
    this.showSensorNameAlert = false;
  }

  updateSchematics(displayData){
    this.signalRService.emitIsHovered(displayData);
  }
  checkForDuplicateNames(displayData, value) {
    let isPresent = false;
    const selectedWell = this.getWellInformation(displayData);
    if (selectedWell.length > 0 && selectedWell[0].sensors && (selectedWell[0].sensors).length > 0){
      selectedWell[0].sensors.map((sensor) => {
          if (sensor.displayName.toUpperCase() === value.toUpperCase()) {
              isPresent = true;
          }
       });
    }
    return isPresent;
  }

  /**
   * @name: setClassOpacity
   * @description: Method set the opacity of other classes on edit
   * @param: {number} opacityValue: the value of opacity to be set
   */
  public setClassOpacity(opacityValue: number): void {
    const classesToOverride = document.querySelectorAll('.measurement-data');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < classesToOverride.length; i++) {
      classesToOverride[i]['style'].opacity = opacityValue;
    }
  }

  public insertRenamedSensorInDB(newLabel: string, oldLabel: string, wellInformation: any) {
    const payload = {
      newLabel,
      oldLabel,
      wells: this.wells,
      currentWell: wellInformation
    };
    this.store.dispatch(new userActions.EditUserSensorName(payload));
  }

  getWellInformation(displayData) {
    const selectedWell = [];
    this.wells.map((well) => {
        if (well.uid === displayData.logid) {
          selectedWell.push(well);
        }
    });
    return selectedWell;
  }

  loadSensorEditedNames(sensorOriginalName: string, sensorWellName: string) {
    this.store
      .select((state: State) => state.user)
      .pipe(first())
      .subscribe((user) => {
        if (
          user &&
          user.preferences &&
          user.preferences.length > 0
        ) {
          const userSensors = user.preferences;
          userSensors.map((userSensor) => {
            if (userSensor.originalSensorLabel === sensorOriginalName && userSensor['sensorWellName'] === sensorWellName) {
              this.sensorEditedName = userSensor.renamedSensorLabel;
            }
          });
        }
      });
  }
 // Function to show battery level indicator
  batteryIndicator(currentValue: string, isPercentage: string) {
    if (currentValue === null) {
      return;
    }
    if (isPercentage === '%'){
      this.currentVoltage = currentValue;
    }else{
      this.currentVoltage = ((parseFloat(currentValue) - 3) / (6 - 3)) * 100;
    }

    this.fourGreenbar = false;
    this.threeGreenbar = false;
    this.twoGreenbar = false;
    this.red = false;
    if (this.currentVoltage > 75) {
      this.fourGreenbar = true;
    } else if (this.currentVoltage > 50 && this.currentVoltage <= 75) {
      this.threeGreenbar = true;
    } else if (this.currentVoltage > 25 && this.currentVoltage <= 50) {
      this.twoGreenbar = true;
    } else if (this.currentVoltage <= 25) {
      this.red = true;
    }
    this.currentVoltage = this.currentVoltage.toFixed(0) + '%';
  }

}
