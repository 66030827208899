import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './containers/login/login.component';
import { MasterPageComponent } from './containers/master-page/master-page.component';
import { AuthGuard } from './guards';

const appRoutes: Routes = [
  {
    path: 'app',
    //  resolve: {
    //    environmentData: PathResolverService
    //  },
    component: MasterPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    //  resolve: {
    //    environmentData: PathResolverService
    //  },
    component: LoginPageComponent
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
