import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
// Import services
import { differenceInCalendarDays } from 'date-fns';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { interval, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ConnectionService } from 'ng-connection-service';
import { CoercionSetting } from 'src/app/models/CoercionSettings';
import { PanelToggleService } from 'src/app/services/panel-toggle.service';
import configClariti from '../../../assets/data/config.json';
// Import interfaces
import { IAlert } from '../../models/alert';
import { Conversion } from '../../models/converterData';
import { DateRange } from '../../models/DateRange';
import { DataSource, DataSourceDataSet, IDataSensor, ILineChartData, IPositions } from '../../models/lineGraphData';
import { IMeasurementData } from '../../models/measurement';
import { UserGlobal } from '../../models/user';
import { AlertDetailsService } from '../../services/alert-details.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ChartLoadingService } from '../../services/chart-loading.service';
import { ChartStoreService } from '../../services/chart.service';
import { CoercionSettingsService } from '../../services/coercion-settings.service';
import { GraphUtilService } from '../../services/graph-util.service';
import { HeaderService } from '../../services/header.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
// Import constants
import { MeasurementConstants, ViewOptions } from '../../shared/constants/master-page.constants';
import { ConversionService } from '../../shared/services/conversion.service';
import { GlobalErrorService } from '../../shared/services/global-error.service';
import { addHoursByTimeZone } from '../../shared/utilities/timeZoneConverter';
import { clearLocalStorage } from '../../shared/utilities/util';
import * as unitConversionAction from '../../state/actions/conversionActions';
import * as userActions from '../../state/actions/userActions';
import { State } from '../../state/container-states/app.state';
import { Well, Sensor } from '../../state/reducers/global-filter.reducer';
import sensorInfo from './../../../assets/data/sensorTypeConfig.json';
import { ERR_MESSAGES } from 'src/app/shared/constants/error-messages.constants';
import { AlertService } from 'src/app/services/alert.service';
import { AuthorizationService } from 'src/app/services/authorization.service.js';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
  providers: [MessageService, ChartLoadingService]
})
export class MasterPageComponent implements OnInit, OnDestroy {
  sensorArray: any[];
  tempLoader: boolean;
  sensorInfo: any[];
  selectedSensor: any;
  loadingView: boolean;
  showLoading: boolean;
  alarmSubscription: any;
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete
  error: Error;
  constructor(
    private chartStoreService: ChartStoreService,
    private tempPressureService: PressureAndTemperatureService,
    private conversionService: ConversionService,
    private store: Store<State>,
    private alertDetailsService: AlertDetailsService,
    private headerService: HeaderService,
    private graphUtilService: GraphUtilService,
    private connectionService: ConnectionService,
    private messageService: MessageService,
    private globalErrorService: GlobalErrorService,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private chartLoadingService: ChartLoadingService,
    private coercionService: CoercionSettingsService,
    private cdRef: ChangeDetectorRef,
    private panelToggleService: PanelToggleService,
    private alertService: AlertService,
    private userService : UserService,
    private router: Router,
  ) {
    // code added to catch online and offline event
    this.error = new Error();
    this.connectionService.monitor().subscribe((isConnected) => {
      if (isConnected) {
        this.messageService.clear('ToastMessageKeyNetwork');
        this.loadUserFilters();
      } else {
        this.error.message = ERR_MESSAGES.NO_CONNECTIVITY;
        this.messageService.clear('ToastMessageKeyNetwork');
        this.showNetworkError(this.error);
        localStorage.setItem('refreshFlag', '1');
      }
    });
    this.sensorInfo = sensorInfo.sensorData;
  }

  configClariti = configClariti;

  livePool$: Subscription;
  syncEntitlements$: Subscription;
  display = false;
  displayDateRangeSelect = false;
  displayDateRangeSelectInteractive = false;
  displayAlertPanel = false;
  dataSourceForResponse: { [key: string]: DataSource[] } = {
    [ViewOptions.oneMonth]: [],
    [ViewOptions.presTemp]: []
  };
  public selectedTab: string;
  public dataRetriever;
  public measurementSensor: IDataSensor[] = [];
  public showControls: boolean;
  public displayConverter: boolean;
  public coercionSettings = false;
  public configDataForService: ILineChartData = {
    timeSelect: 7,
    sensorArray: []
  };

  public chartDateRange: DateRange = {
    start: new Date(Date.now() - 3600000),
    end: new Date()
  };
  public converterData: Conversion;
  // Variable to store response from service
  public temperaturePressureResponse;
  // Variable to carry service data
  public dataInput: IMeasurementData = {
    measurementData: MeasurementConstants.MEASUREMENT_DATA,
    measurementLabel: MeasurementConstants.MEASUREMENT_LABEL,
    pressureSensorLabel: MeasurementConstants.PRESSURE_SENSOR_LABEL,
    pressureUnit: MeasurementConstants.PRESSURE_UNIT,
    temperatureSensorLabel: MeasurementConstants.TEMPERATURE_SENSOR_LABEL,
    temperatureUnit: MeasurementConstants.TEMPERATURE_UNIT,
    pitchangerateUnit: MeasurementConstants.PITCHANGERATE_UNIT
  };

  public openedAlert: IAlert;
  public openAlertDetails: boolean;
  public userDetails: UserGlobal;
  public wellList: Well[];
  public showHistoryView = true;
  dataSourceGraph: { [key: string]: DataSource[] } = {
    [ViewOptions.oneMonth]: [],
    [ViewOptions.presTemp]: []
  };
  notificationStatus = false;
  notificationLength = '';
  acknowledgedItems: any;
  selectedLiveReadingsSchematics: any[] = [];
  selectedSensorName = '';
  wellNameFromMap = false;
  showPressure = true;
  sensorArrayUpdated: any[];
  dateSelectionChanged = false;
  liveFeed = true;
  mappedColor: { [key: string]: string } = {};
  unsubscribe$ = new Subject();
  viewOptions = ViewOptions;
  convertedChartDateRange: DateRange = {
    start: undefined,
    end: undefined
  };
  public localData: any = {
    pressureUnit: '',
    temperatureUnit: '',
    pitchangerateUnit:'',
  };
  multipleTab: boolean;
  waitMessage = 'Please wait...';
  /**
   * Angular lifecycle hook
   */
  ngOnInit() {
    this.messageService.clear('sessionExpired');
    localStorage.setItem('refreshFlag', '1');
    this.loadingView = true;
    this.showLoading = this.configClariti.charts.Method === 'local' ? true : false; // to show dashbord loading for localsite
    this.chartStoreService.currentAxisList.pipe(takeUntil(this.unsubscribe$)).subscribe((payload: any) => {
      this.sensorArray = [];
      if (payload.axisList !== undefined && payload.axisList.length > 0) {
        payload.axisList.forEach((axis) => {
          if (axis.checked) {
            axis.sensorList.forEach((sensor) => {
              this.sensorArray.push(sensor);
            });
          }
        });
      }
    });

    const source = interval(60000);
    this.syncEntitlements$ = source.subscribe(val => this.checkEntitilementsChange());
    // check global errors
    this.checkGlobalErrors();

    // To Get user details
    this.getUserDetails();
    this.userService.getRoles().subscribe((data: any) => {}),
    ((err)=>{
      console.log(`error in fetching roles: ${err}`);
    });
    // Load User saved data from IndexDB and update NGRx state
    this.loadUserPreferences();
    setTimeout(() => {
      this.loadUserFilters();
      this.alarmSubscription = this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {

        this.isapiInprogress = wellState.apiInprogress;
          if (wellState && wellState.wells && wellState.wells.length > 0 && !this.isapiInprogress) {
            this.alertService.stopAlertTimer();
            this.alertService.startAlertTimer(wellState.wells);
          } else {
            this.alertService.stopAlertTimer();
          }
        
    })
    }, 3000);

    this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        if (conversionState) {
          this.converterData = conversionState.conversion;
          if (this.coercionSettings) {
            this.updateCoercionDataSource();
          } else {
            this.dataSourceForResponse[ViewOptions.presTemp] = [];

            //code change for config file implementation
            for (let i = 0; i < this.selectedLiveReadingsSchematics.length; i++) {
              this.sensorInfo.forEach((sensorList) => {
                if (this.selectedLiveReadingsSchematics[i].sensorType.toLowerCase() === sensorList.sensorType && this.selectedLiveReadingsSchematics[i][sensorList.sensorType] !== 'Waiting...' &&
                  this.localData[sensorList.sensorType + 'Unit'] !== this.converterData[sensorList.sensorType + 'Unit']) {
                  this.selectedLiveReadingsSchematics[i][sensorList.sensorType] = this.conversionService['convert' + sensorList.sensorType](this.localData[sensorList.sensorType + 'Unit'], this.selectedLiveReadingsSchematics[i][sensorList.sensorType], this.converterData[sensorList.sensorType + 'Unit']);
                  this.dataInput[sensorList.sensorType + 'Unit'] = this.converterData[sensorList.sensorType + 'Unit'];
                  this.localData[sensorList.sensorType + 'Unit'] = this.converterData[sensorList.sensorType + 'Unit'];
                }
              });
            }
          }
        }
      });

    this.store
      .select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.configDataForService.sensorArray = [];
        this.measurementSensor = [];
        this.dataInput.measurementData = [];
        this.clearOutAllDataSourceForResponse();
        for (const item of wellState.wells) {
            if (item.sensors && item.sensors.length > 0 && item.checked) {
            this.createSensor(item.sensors, item.uidWell, item.uid);
          }
          if (item.checked === true && this.measurementSensor.length === 0) {
            this.selectedSensorName = item.wellName;
            this.wellNameFromMap = true;
          }
        }
        this.wellList = wellState.wells;
        this.mappedColor = {}; //reset the color mapping for each sensors
         this.configDataForService.sensorArray.forEach((sensor) => {
           this.assignColor(sensor.wellName + '-' + sensor.sensorOriginalName);
         });
      });


    let selectedComponent = localStorage.getItem('component');
    if (selectedComponent === undefined || selectedComponent === null) {
      selectedComponent = 'map';
      if (this.configClariti.livereadings.Method === 'local') {
        selectedComponent = this.setDefaultTabToOpen();
      }
    }

    this.selectedTab = ViewOptions[selectedComponent];

    this.showControls = false;

    this.alertDetailsService.openAlertDetailsModal
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((alert) => {
        this.openedAlert = alert;
        this.openAlertDetails = true;
      });
    this.chartLoadingService.sliderValue.next(Date.now());
    // For multiple tab error message.
    this.panelToggleService.currentSelection.subscribe((payload) => {
      if (payload) {
        payload.typeSelected.toLowerCase() === 'multiple' ? this.multipleTab = true : this.multipleTab = false;
        this.selectedSensor = payload.typeSelected;
        this.cdRef.detectChanges();
      }
    });
  }

  setDefaultTabToOpen() {
    const pages = {
      maps: 'map',
      schematics: 'schematics',
      charts: 'charts',
      alerts: 'alertRules',
      export: 'export'
    };

    for (const key in pages) {
      if (this.configClariti[key].show === true) {
        this.toggleTab(this.viewOptions[key]);
        return pages[key];
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.syncEntitlements$.unsubscribe();
    this.alarmSubscription.unsubscribe();
  }

  showDataRetrievalError(error) {
    this.chartStoreService.stopLivePool();
    this.messageService.clear('ToastMessageKey');
    this.messageService.add({
      key: 'ToastMessageKey',
      severity: 'error',
      summary: error.message,
      closable: false,
      detail: '',
      sticky: true
    });
  }

  //if there is a 401 unauthorized error, show there is a change in the entitlements
  showAuthorizationError(error) {
    this.chartStoreService.stopLivePool();
    this.messageService.clear('sessionExpired');
    this.messageService.add({
      key: 'sessionExpired',
      severity: 'error',
      summary: error.message,
      closable: false,
      detail: '',
      sticky: true
    });
  }

  showNetworkError(error) {
    this.messageService.clear('ToastMessageKeyNetwork');
    this.messageService.add({
      key: 'ToastMessageKeyNetwork',
      severity: 'error',
      summary: error.message,
      closable: false,
      detail: '',
      sticky: true
    });
  }

  updateCoercionDataSource() {
    if (this.dataSourceForResponse[ViewOptions.oneMonth]) {
      for (const item of this.dataSourceForResponse[ViewOptions.oneMonth]) {
        this.convertCachedData(item);
      }
    }
    this.dataSourceGraph[this.viewOptions.oneMonth] = JSON.parse(
      JSON.stringify(this.dataSourceForResponse[ViewOptions.oneMonth])
    );
  }

  //check if the user entitlements have changed in OWAI.
  checkEntitilementsChange() {
    this.authorizationService.syncEntitlements().subscribe((result) => {
      if (!result) {
        this.error.message = ERR_MESSAGES.ENTITLEMENTS_UPDATED_PROMPT;
        this.messageService.clear('sessionExpired');
        this.showAuthorizationError(this.error);
      }
    }, (error) => {
        this.error.message = ERR_MESSAGES.SESSION_EXPIRED;
        this.showAuthorizationError(this.error);
    });
  }

  /**
   * @name: createSensor
   * @description: Method to form sensors
   * @param: sensorArray {Sensor[]}: Array holding the sensor data
   * @param: uidWellSensor {string} well uid
   * @param: uidWellLog {string} log id for associated well
   */
  createSensor(
    sensorArray: Sensor[],
    uidWellSensor: string,
    uidWellLog: string
  ): void {
    let isSensorChecked = false;
    for (const sensorItem of sensorArray) {
      if (sensorItem.checked) {
        const dataSensor: IDataSensor = {
          uid: uidWellLog,
          uidWell: uidWellSensor,
          sensorName: sensorItem.sensorName,
          unitType: sensorItem.unitType.split(' ').join('').toLowerCase(),
          displayName: sensorItem.displayName,
          wellName: sensorItem.wellName,
          multiLogId: sensorItem.multiLogId,
          sensorOriginalName: sensorItem.sensorOriginalName,
          combinedMnemonics: sensorItem.wellName + '-' + sensorItem.sensorOriginalName,
          batterySensorName: sensorItem.batterySensorName
        };
        isSensorChecked = true;
        const isDataExist =
          this.configDataForService.sensorArray.filter((filteredData) => {
            return filteredData.sensorName === dataSensor.sensorName && filteredData.wellName === dataSensor.wellName;
          }).length > 0;
        if (!isDataExist) {
          this.configDataForService.sensorArray.push(dataSensor);
          this.measurementSensor.push(dataSensor);
        }
      }
    }
  }

  convertCachedData(item) {
    item.responseFromServiceToBeConverted.data = JSON.parse(
      JSON.stringify(item.responseFromService.data)
    );
    for (const itemData of item.responseFromServiceToBeConverted.data) {
      itemData[0] = this.conversionService.convertTime(
        this.converterData.timeUnit,
        itemData[0]
      );
      this.sensorInfo.forEach((sensorList) => {
        if (sensorList.sensorType.toLowerCase() === item.typeService) {
          itemData[1] = this.conversionService['convert' + sensorList.sensorType](this.converterData[sensorList.sensorType + 'Unit'], itemData[1]);
        }
      });
    }
    item.responseFromServiceConverted = item.responseFromServiceToBeConverted;
  }

  //hide the user entitlements sync error popup and log user out
  hideDataSyncError() {
    this.messageService.clear();
    this.error.message = "";
    this.authenticationService.logoutCompletelyRedirectToLogin();
  }

  hideSessionExpiredMsg() {
    if (this.configClariti.charts.Method === 'local'){
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      clearLocalStorage();
      this.messageService.clear();
      this.router.navigate(['login']);
    }else{
      this.messageService.clear();
    }

  }

  showSeesionExpiredMsg(err) {
    this.messageService.clear();
    this.authenticationService.logoutCompletelyRedirectToLogin();
  }

 chooseDataSourceForChart() {
    if (this.selectedTab === this.viewOptions.oneMonth) {
      return this.dataSourceGraph[this.viewOptions.oneMonth];
    } else {
      return this.dataSourceGraph[this.viewOptions.presTemp];
    }
  }

  /**
   * @name: toggleTab
   * @description: Method to toggle between pressure temperature and alerts
   * @param: selectedTab {boolean}: flag to control toggle
   */
  public toggleTab(selectedTab: string): void {
    if (navigator.onLine) {
      this.messageService.clear('ToastMessageKey');
    }
    const previousTab = this.selectedTab;
    const noLiveReadingsTabs = [this.viewOptions.oneMonth, this.viewOptions.export];
    this.selectedTab = selectedTab;
    this.coercionSettings = selectedTab === this.viewOptions.oneMonth;
    this.shouldSpinnerHide();
    if (!this.coercionSettings) this.showHistoryView = true;
    if (
      selectedTab === this.viewOptions.oneMonth ||
      selectedTab === this.viewOptions.presTemp
    ) {
      this.temperaturePressureResponse = [];
      this.dataSourceForResponse[selectedTab] = [];
      this.coercionService.changeSelection(this.coercionSettings);
      if (selectedTab === this.viewOptions.oneMonth) {
        this.chartDateRange = null;
      }
    }

  }

  shouldSpinnerHide() {
    if (this.selectedTab !== this.viewOptions.oneMonth) {
      this.chartLoadingService.hideSpinner(this.viewOptions.oneMonth);
    } else if (this.selectedTab !== this.viewOptions.presTemp) {
      this.chartLoadingService.hideSpinner(this.viewOptions.presTemp);
    }
  }

  enableSideMenu() {
    this.loadingView = false;
    const menuContent = document.getElementById('menu-list');
    if (menuContent) {
      menuContent.classList.remove('isLocked');
    }
  }
  enableLoaderCharts() {
    this.tempLoader = true;
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.chartDateRange = dateRange;
    this.convertedChartDateRange = {
      start: addHoursByTimeZone(new Date(dateRange.start.valueOf()), this.converterData.timeUnit),
      end: addHoursByTimeZone(new Date(dateRange.end.valueOf()), this.converterData.timeUnit)
    };
    if (dateRange.start !== undefined && dateRange.end !== undefined) {
      this.buildChartDataforDateRange(this.chartDateRange);
    }
  }

  onDateRangeChangedInteractive(dateRange: DateRange) {
    this.chartDateRange = dateRange;
    this.convertedChartDateRange = {
      start: addHoursByTimeZone(new Date(dateRange.start.valueOf()), this.converterData.timeUnit),
      end: addHoursByTimeZone(new Date(dateRange.end.valueOf()), this.converterData.timeUnit)
    };
    if (dateRange.start !== undefined && dateRange.end !== undefined) {
      this.buildChartDataforDateRangeInteractive(this.chartDateRange);
    }
  }

  /**
   * @name: buildChartDataforOneMonth
   * @description: Method to getchart pressure temperature for a month
   * @param: coerscionSettings {object}:
   */
  public buildChartDataforDateRangeInteractive(dateRange: DateRange) {
    this.chartLoadingService.showSpinner(this.viewOptions.presTemp);
    this.showHistoryView = false;
    this.dataSourceForResponse[ViewOptions.presTemp] = [];
    this.cdRef.detectChanges();
    let finalDataSource;
    const noOfDays: number = differenceInCalendarDays(
      this.convertedChartDateRange.end,
      this.convertedChartDateRange.start
    );
    const coercionSettings: CoercionSetting = this.coercionService.getCoercionSettings(
      noOfDays
    );
    this.tempPressureService
      .joinDataForAllWells(this.measurementSensor, dateRange, coercionSettings)
      .subscribe((logs: any) => {
        if (logs.length > 0 && this.isLogCurveInfoAvailable(logs)) {
          finalDataSource = this.graphUtilService.buildSensorDataforChart(logs);
          for (const item of this.measurementSensor) {
            const dataToProcess = finalDataSource.filter((dataItem) => {
              return item.sensorName === dataItem.sensorName && item.wellName === dataItem.nameWell;
            });
            if (dataToProcess.length > 0) {
              this.processData(dataToProcess[0], item, false, this.viewOptions.presTemp);
            }
          }
        }
        else {
          this.chartLoadingService.hideSpinner(this.viewOptions.presTemp);
          this.dataSourceGraph[this.viewOptions.presTemp] = [];
        }
      });
  }

  isLogCurveInfoAvailable(logs) {
    let available = false;

    for (const log of logs) {
      if (log[0] && log[0]['logCurveInfo'] && log[0]['logCurveInfo'].length > 0) {
        available = true;
        break;
      }
    }
    return available;
  }
  /**
   * @name: buildChartDataforOneMonth
   * @description: Method to getchart pressure temperature for a month
   * @param: coerscionSettings {object}:
   */
  public buildChartDataforDateRange(dateRange: DateRange) {
    this.chartLoadingService.showSpinner(this.viewOptions.oneMonth);
    this.showHistoryView = false;
    this.dataSourceForResponse[ViewOptions.oneMonth] = [];
    this.cdRef.detectChanges();
    let finalDataSource;
    const noOfDays: number = differenceInCalendarDays(
      this.convertedChartDateRange.end,
      this.convertedChartDateRange.start
    );
    const coercionSettings: CoercionSetting = this.coercionService.getCoercionSettings(
      noOfDays
    );
    this.tempPressureService
      .joinDataForAllWells(this.measurementSensor, dateRange, coercionSettings)
      .subscribe((logs: any) => {
        if (logs.length > 0 && logs[0][0]['logCurveInfo'].length > 0) {
          finalDataSource = this.graphUtilService.buildSensorDataforChart(logs);
          for (const item of this.measurementSensor) {
            const dataToProcess = finalDataSource.filter((dataItem) => {
              return item.sensorName === dataItem.sensorName && item.wellName === dataItem.nameWell;
            });
            if (dataToProcess.length > 0) {
              this.processData(dataToProcess[0], item, false, this.viewOptions.oneMonth);
            }
          }
        }
        else {
          this.chartLoadingService.hideSpinner(this.viewOptions.oneMonth);
          this.dataSourceGraph[this.viewOptions.oneMonth] = [];
        }
      });
  }

  /**
   * @name: showControlsClick
   * @description: Method to toggle settings section
   */
  public showControlsClick(): void {
    this.showControls = !this.showControls;
  }

  /**
   * @name: setShowChart
   * @description: set data for response which has no data
   * @param: responseFromService
   * @param: sensorDetails
   */
  private setShowChart(responseFromService, sensorDetails): boolean {
    const hasDataInResponse: boolean =
      responseFromService['logData']['data'].length > 0;
    if (!hasDataInResponse) {
      const responseFromServiceTemp = responseFromService['logData'];
      const unitLists = [
        this.converterData.temperatureUnit,
        this.converterData.pressureUnit,
        this.converterData.pitchangerateUnit
      ];
      responseFromServiceTemp.units = unitLists;
      responseFromServiceTemp['sensorName'] = sensorDetails['displayName'];
      responseFromServiceTemp['wellName'] = sensorDetails['wellName'];
      responseFromServiceTemp['sensorOriginalName'] = sensorDetails['sensorName'];
      responseFromServiceTemp['uidWell'] = sensorDetails['uidWell'];
      responseFromServiceTemp['data'] = [];
      const positions: IPositions = this.graphUtilService.getPositions(
        responseFromService['logCurveInfo']
      );
      responseFromServiceTemp['typeService'] =
        positions.temperature === ''
          ? positions.pressure === ''
            ? ''
            : 'pressure'
          : 'temperature';
      const dataSourceForBlankResponse: DataSource = {
        sensorName: responseFromServiceTemp['sensorOriginalName'],
        wellName: sensorDetails['wellName'],
        typeService: '',
        responseFromService: responseFromServiceTemp,
        responseFromServiceConverted: responseFromServiceTemp,
        responseFromServiceToBeConverted: ''
      };
      const isDataAlreadyPresent =
        this.dataSourceForResponse[ViewOptions.presTemp].filter((responsePresent) => {
          return responsePresent.sensorName === sensorDetails['sensorName'] && responsePresent.wellName === sensorDetails['wellName'];
        }).length > 0;
      if (!isDataAlreadyPresent) {
        this.dataSourceForResponse[ViewOptions.presTemp].push(dataSourceForBlankResponse);
        const allDataPresent =
          this.dataSourceForResponse[ViewOptions.presTemp].length ===
          this.configDataForService.sensorArray.length;
        if (allDataPresent) {
          this.dataSourceGraph[this.viewOptions.presTemp] = JSON.parse(
            JSON.stringify(this.dataSourceForResponse[ViewOptions.presTemp])
          );
        }
      }
    }
    return hasDataInResponse;
  }

  getWellName(wellUid) {
    const well = _.find(this.wellList, { uidWell: wellUid });
    if (well && well.wellName) {
      return well.wellName;
    }
    return '';
  }

  getStartDate(): Date {
    const dateStart: Date = new Date(Date.now() - 3600000);
    return dateStart;
  }

  /**
   * @description: method to get temperature data
   */
  getChartData(fromLive: boolean): void {
    if (this.selectedTab === this.viewOptions.oneMonth || this.selectedTab === this.viewOptions.export) return;
    this.dataSourceForResponse[ViewOptions.presTemp] = [];
    this.chartLoadingService.sliderValue.pipe(first()).subscribe((value) => {
      if (!fromLive) {
        this.chartLoadingService.requestInitiated = true;
        this.chartDateRange = {
          start: new Date(value - this.chartLoadingService.xAxisTimeRange),
          end: new Date(value)
        };
      }
      if (!this.configDataForService.sensorArray.length) {
        return this.chartLoadingService.hideSpinner(this.viewOptions.presTemp);
      }
      // tslint:disable-next-line:no-console
      for (const item of this.configDataForService.sensorArray) {
        this.tempPressureService
          .getPressureAndTemperatureData(
            this.chartDateRange,
            item,
            this.coercionSettings
          )
          .pipe(first())
          .subscribe(
            (response): void => {
              this.chartLoadingService.requestInitiated = false;
              // STRIP down response to send only essentials
              const responseData = response['response']['log'][0];
              if (this.setShowChart(responseData, item)) {
                if (responseData['hasMoreData']) {
                  this.getMoreData(responseData, item);
                } else {
                  this.processData(responseData, item, false, this.viewOptions.presTemp);
                }
              }
            }
          );
      }
    });
  }

  /**
   * @name: getMoredata
   * @description: Method to get more data from service if more data flag is enabled
   * @param: responseFromPrevious: Response from previous service
   * @param: item: Name of sensor
   */
  getMoreData(responseFromPrevious, item): void {
    const responsePrevData = responseFromPrevious['logData']['data'];
    const lengthData = responsePrevData.length;
    const dateRange: DateRange = {
      start: new Date(responseFromPrevious.endDateTimeIndex + 'Z'),
      end: this.chartDateRange.end
    };
    this.tempPressureService
      .getPressureAndTemperatureData(
        dateRange,
        item,
        this.coercionSettings
      )
      .subscribe(
        (response): void => {
          if (this.checkPressureTempServiceError(response)) {
            // STRIP down response to send only essentials
            const responseData = response['response']['log'][0];
            for (const itemData of responseData['logData']['data']) {
              responseFromPrevious['logData']['data'].push(itemData);
            }
            if (responseData['hasMoreData']) {
              this.getMoreData(responseFromPrevious, item);
            } else {
              this.processData(responseFromPrevious, item.displayName, false, this.viewOptions.presTemp);
            }
          }
        }
      );
  }

  /**
   * @name: processData
   * @description: Method to process data from service
   * @param: response: response from service
   * @param: item: sensor name
   * @param: measurement: boolean value to determine if data is for measurement
   * calculation
   */
  processData(response, item, measurement?, destination?): void {
    // Method to identify if graph has temperature OR pressure
    // SEND object back to get temperature/pressure/time positions
    const positions: IPositions = this.graphUtilService.getPositions(
      response['logCurveInfo']
    );
    if (
      positions.dateTime === '' &&
      positions.pressure === '' &&
      positions.temperature === ''
    ) {
      positions.pressure = response.typeService === 'Pressure' ? '1' : '';
      positions.temperature = response.typeService === 'Temperature' ? '1' : '';
    }
    // Check if has more data
    // SEND CALL AGAIN with start Time modified/ push into variable

    // IF not clear object send for display manipulation
    if (measurement) {
      this.transformDataForDisplay(response, item, positions, measurement);
    } else {
      this.transformDataForDisplay(response, item, positions, false, destination);
    }
  }

  /**
   * @name: transformDataForDisplay
   * @description: method to transform data for display
   * @param: response: response from service
   * @param: item: sensor name
   * @param: positions {IPositions} array indexes for temperature and pressure
   * @param: measurement {boolean}: to signify if measurement calculation
   */
  transformDataForDisplay(
    response,
    item,
    positions: IPositions,
    measurement?: boolean,
    destination?
  ) {
    let responseDataSet = '';
    let dataSet: DataSourceDataSet;
    if (measurement) {
      // Measurement Calculation
      responseDataSet = this.graphUtilService.setUpResponseObject(
        response,
        positions,
        item
      );
      responseDataSet['data'].splice(0, responseDataSet['data'].length - 1);
    } else {
      // Chart Calculations
      dataSet = this.graphUtilService.buildData(
        item,
        positions,
        response,
        this.dataSourceForResponse[destination]
      );
      this.dataSourceForResponse[destination] = dataSet.dataSource;
      responseDataSet = this.dataSourceForResponse[destination][dataSet.index]
        .responseFromServiceToBeConverted;
    }
    if (responseDataSet['typeService'] !== '' && dataSet) {
      responseDataSet = this.convertData(responseDataSet, positions);
        if (
          this.dataSourceForResponse[destination][dataSet.index]
            .responseFromServiceConverted === ''
        ) {
          this.dataSourceForResponse[destination][
            dataSet.index
          ].responseFromServiceConverted = responseDataSet;
        } else {
          this.dataSourceForResponse[destination][dataSet.index]
            .responseFromServiceConverted['data'] = this.dataSourceForResponse[destination][
              dataSet.index
            ].responseFromServiceConverted['data'].concat(responseDataSet['data']);
        }
    }

    if (measurement) return;
    const sensorArray = this.selectedTab === ViewOptions.oneMonth ?
      this.measurementSensor :
      this.configDataForService.sensorArray;
    const isAllDataPresent =
      this.dataSourceForResponse[destination].length >= sensorArray.length || !this.liveFeed;

    if (isAllDataPresent && responseDataSet['data'].length > 0) {
      // SEND DATA FOR ON CHANGES
      this.dataSourceGraph[destination] = [];
      if (this.selectedTab === ViewOptions.presTemp) {
        this.dataSourceForResponse[destination] =
          this.dataSourceForResponse[destination].filter(
            (sensor) => {
              return this.configDataForService.sensorArray.find(
                (selectedSensor) => {
                  return selectedSensor.sensorName === sensor.sensorName && selectedSensor.wellName === sensor.wellName;
                }
              );
            }
          );
      }
      this.dataSourceGraph[destination] = JSON.parse(
        JSON.stringify(this.dataSourceForResponse[destination])
      );
    }
  }

  getLastElement(data) {
    return _.last(data);
  }

  getSensorType(response) {
    if (response['mnemonic']) {
      return (response['mnemonic']).toLowerCase().includes('temp') ? 'temperature' : 'pressure';
    }
  }

  getTypeService(response) {
    let type;
    if (response['unit_type']) {
      type = this.sensorInfo.filter((sensorList) => {
        return response['unit_type'].split(' ').join('').toLowerCase() === sensorList.sensorType;
      });
    }
    return type[0].sensorType.split(' ').join('').toLowerCase();
  }

  getSorted(unsorted) {
    return unsorted.sort((a, b) => a.sensorLabel.localeCompare(b.sensorLabel, undefined, { numeric: true }));
  }

   assignColor = (value) => {
     const index = Object.keys(this.mappedColor).length;
     if (!this.mappedColor[value]) {
       this.mappedColor[value] = MeasurementConstants.COLOR_SCHEMES[index];
     }
     return this.mappedColor[value];
   }

  getPresOrTemp(displayPressure) {
    this.showPressure = displayPressure;
  }

  /**
   * @name: isDataAvailable
   * @description: Method to check if data pre exists
   * @param: sensorLabel {string} name of sensor
   * @returns: {number} index of the position
   */
  isDataAvailable(sensorLabel: string, wellName: string): number {
    // tslint:disable-next-line:no-inferrable-types
    let index: number = -1;
    for (const item in this.dataInput.measurementData) {
      if (this.dataInput.measurementData[item].sensorLabel === sensorLabel && this.dataInput.measurementData[item].wellName === wellName) {
        index = parseFloat(item);
      }
    }
    return index;
  }

  /**
   * @name: showDialog
   * @description: Method to open global filters modal
   */
  showDialog() {
    this.display = true;
  }

  /**
   * @name: hideDialog
   * @description: Method to close global filters modal
   */
  hideDialog() {
    this.display = false;
  }

  /**
   * @name: toggleAlertPanel
   * @description: Method to toggle alert panel
   */
  toggleAlertPanel() {
    this.displayAlertPanel = !this.displayAlertPanel;
  }

  /**
   * @name: openSettings
   * @description: Method to open settings modal
   */
  public openSettings(): void {
    this.showControls = false;
    this.displayConverter = true;
  }

  /**
   * @name: hideModal
   * @description: Method to hide settings modal
   */
  public hideModal(): void {
    if (this.liveFeed) {
      clearInterval(this.dataRetriever);
    }
    this.displayConverter = false;
  }

  public showDateRangeSelectHandler($event): void {
    this.displayDateRangeSelect = $event;
  }

  /**
   * @name: hideDateRangeSelDialog
   * @description: Method to close global Date range selector modal
   */
  hideDateRangeSelDialog($event) {
    this.displayDateRangeSelect = $event;
  }

  public showDateRangeSelectHandlerInteractive($event): void {
    this.displayDateRangeSelectInteractive = $event;
  }

  /**
   * @name: hideDateRangeSelDialogInteractive
   * @description: Method to close global Date range selector modal
   */
  hideDateRangeSelDialogInteractive($event) {
    this.displayDateRangeSelectInteractive = $event;
  }

  /**
   * @name: convertData
   * @description: Method to convert data to different units
   * @param: temperaturePressureResponse response from service
   * @param: positions {IPositions}: Position for temp/ pressure
   */
  convertData(temperaturePressureResponse, positions: IPositions) {
    const unitLists = [
      this.converterData.temperatureUnit,
      this.converterData.pressureUnit,
      this.converterData.pitchangerateUnit
    ];
    temperaturePressureResponse.units = unitLists;
    const tempResponse = [];
    if (this.converterData) {
      for (const item of temperaturePressureResponse['data']) {
        item[positions.dateTime] = this.conversionService.convertTime(
          this.converterData.timeUnit,
          item[positions.dateTime]
        );
        this.sensorInfo.forEach((sensorList) => {
          if (sensorList.sensorType.toLowerCase() === temperaturePressureResponse['typeService']) {
            item[positions[sensorList.sensorType.toLowerCase()]] = this.conversionService['convert' + sensorList.sensorType](this.converterData[sensorList.sensorType + 'Unit'], item[1]);
          }
          tempResponse.push(item);
        });

        temperaturePressureResponse['data'] = [...tempResponse];
      }
    }
    return temperaturePressureResponse;
  }

  /**
   * @name: onDateSelectionChanged
   * @description: Method called on date selection
   * @param: event {DateRange}
   */
  public onDateSelectionChanged(event: DateRange) {
    this.dataSourceForResponse[ViewOptions.presTemp] = [];
    this.chartDateRange = event;
    this.dateSelectionChanged = true;
  }

  /**
   * @name: pauseResumeDataFeed
   * @description: Method to start/pause live feed
   */
  public pauseResumeDataFeed(feed: boolean): void {
    if (feed) {
      this.liveFeed = true;
      this.chartDateRange.end = new Date();
    } else {
      this.liveFeed = false;
      if (this.chartDateRange) {
        this.chartLoadingService.sliderValue.next(this.chartDateRange.end.valueOf());
      }
    }
  }

  /**
   * @name: showFilteredData
   * @description: Method to show filtered data
   */
  public showFilteredData(event): void {
    this.configDataForService.sensorArray = event;
    this.dataSourceForResponse[ViewOptions.presTemp] = [];
    this.sensorArrayUpdated = event;
  }

  /**
   * @name: hideAlertDetails
   * @description: Method to hide alert details
   */
  public hideAlertDetails($event): void {
    this.openAlertDetails = $event;
  }

  /**
   * @name: clickedOutside
   * @description: Method to catch outside div click
   */
  @HostListener('click')
  clickedOutside() {
    this.headerService.showSettingsMenu.next(false);
  }

  /**
   * @name: getUserDetails
   * @description: Method to get User details
   */
  public getUserDetails(): void {
    // TODO: store/fetch user data from NGRx state
    if (localStorage['currentUser']) {
      this.userDetails = JSON.parse(localStorage['currentUser']);
      // Loading Unit conversion from Index DB
      this.store.dispatch(
        new unitConversionAction.LoadUnitConversion(this.userDetails)
      );
    }
  }

  public loadUserPreferences() {
    this.store.dispatch(new userActions.AddUserPreferences(this.userDetails));
  }

  public loadUserFilters() {
    if (localStorage['currentUser']) {
      const userDetails = JSON.parse(localStorage['currentUser']);
      this.store.dispatch(new userActions.LoadUserFilters(userDetails));
    }
  }

  setNotificationStatus(notificationStatus) {
    const emptyMessage = notificationStatus.alertList.filter((alert) => {
      return alert.AlarmDetail.NotificationMessage.trim() === '';
    });
    const messageLength = notificationStatus.alertLength - emptyMessage.length;

    if (messageLength <= 0) {
      this.notificationStatus = false;
      this.notificationLength = '';
    } else {
      this.notificationStatus = notificationStatus.alertTriggered;
      this.notificationLength = messageLength + '';
    }
  }

  getAcknowledgedItems(acknowledgedItems) {
    this.acknowledgedItems = acknowledgedItems;
  }

  checkGlobalErrors() {
    this.globalErrorService
      .get401Error()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((errors) => {
        if (errors) {
          // special case in 401 message check
          if(this.error.message !=  ERR_MESSAGES.ENTITLEMENTS_UPDATED_PROMPT){
              this.error.message = ERR_MESSAGES.ENTITLEMENTS_UPDATED_PROMPT;
              this.showAuthorizationError(this.error);
          }
        }
      });

    this.globalErrorService
      .get200Error()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        if (error) {
          this.showDataRetrievalError(error);
        }
      });

    this.globalErrorService
      .get5xxError()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        if (error) {
          this.showDataRetrievalError(error);
        }
      });
  }

  checkPressureTempServiceError(response: any): boolean {
    const request = response.request;
    if (
      request &&
      request.hasOwnProperty('IsValid') &&
      !JSON.parse(request.IsValid)
    ) {
      this.globalErrorService.set200Error(response, 'ERR_PANDT_SERVICE_ISVALID');
      return false;
    } else {
      return true;
    }
  }

  hideMainSection() {
    return this.selectedTab === this.viewOptions.export || this.selectedTab === this.viewOptions.map || this.selectedTab === this.viewOptions.schematic || this.selectedTab === this.viewOptions.newSchematic;
  }

  isHistoricalView() {
    return this.selectedTab === this.viewOptions.oneMonth;
  }

  chartLoadCancel($event) {
    if (this.isHistoricalView() && $event) {
      this.tempPressureService.cancellAPICalls();
      this.showHistoryView = true;
    }
  }

  clearOutAllDataSourceForResponse() {
    Object.keys(this.dataSourceForResponse).forEach((key) => {
      this.dataSourceForResponse[key] = [];
    });
  }

  clearOutAllDataSourceForCharts() {
    Object.keys(this.dataSourceGraph).forEach((key) => {
      this.dataSourceGraph[key] = [];
    });
  }

}
