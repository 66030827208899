import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GlobalErrorService } from '../services/global-error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private globalErrorService: GlobalErrorService
  ) { }
  // Error handling has to be refactored and generalised
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status >= 500) {
          this.handle5xx(err, err.url);
        } else if (err.status >= 400 && err.status !== 401 && err.status !== 403 && err.status !== 500) {
          this.handle4xx(err, err.url);
        } else if (err.status === 401) {
          this.handle401(err, err.url);
        }
      }

      return throwError(err);
    }));
  }

  private handle4xx(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set4xxError(err);
  }

  private handle5xx(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set5xxError(err);
  }

  private handle401(err: any, url: string) {
    err['endpointUrl'] = url;
    this.globalErrorService.set401Error(err);
  }
}
