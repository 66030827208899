import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserGlobal } from '../models/user';
import { BaseService } from '../shared/services/indexdb/base.service';

@Injectable({
  providedIn: 'root',
})
export class SensorNameEditService {

  constructor(
    private dbService: BaseService
  ) {}

  getuserSensors(user: UserGlobal): Observable<any> {
    const response =  from(this.dbService.connection.select({
      from: 'UserPreferences',
      where: {
        userId: user.email,
      },
    }));
    return response;
  }

  getSensorUpdatedName(sensorName: string, wellName: string): Observable<any> {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const response = from (this.dbService.connection.select({
      from: 'UserPreferences',
      where: {
        userId: user.email,
        originalSensorLabel: sensorName,
        sensorWellName: wellName
      },
    }));
    return response;
  }

  updateSensorName(newLabel: string, oldLabel: string, wellName: string): Observable<any> {
    if (localStorage['currentUser']) {
      const user = JSON.parse(localStorage['currentUser']);

      return this.getSensorUpdatedName(oldLabel, wellName).pipe(
        map((res) => {
          if (res && res.length > 0) {
            return from(this.dbService.connection.update({
              in: 'UserPreferences',
              set: {
                renamedSensorLabel: newLabel
              },
              where: {
                userId: user['email'],
                originalSensorLabel: oldLabel,
                sensorWellName:wellName,
              }
            }));
          } else {
            return from(this.dbService.connection.insert({
              into: 'UserPreferences',
              return: true,
              values: [{
                userId: user['email'],
                originalSensorLabel: oldLabel,
                renamedSensorLabel: newLabel,
                sensorWellName: wellName,
              }],
            }));
          }
        })
      );
    }
  }
}
