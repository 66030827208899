import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Conversion } from 'src/app/models/converterData';
import { IDataSensor } from 'src/app/models/lineGraphData';
import { GraphUtilService } from 'src/app/services/graph-util.service';
import configClariti from 'src/assets/data/config.json';
import * as XLSX from 'xlsx';
import { DateRange } from '../../models/DateRange';
import { ExportService } from '../../services/export.service';
import { PressureAndTemperatureService } from '../../services/pressure-temperature.service';
import { dateAsString, DateTimeFormatOptions, DateTimeFormatOptionsLocal, DecimalsOptions, DelimiterOptions, warrantyMessage } from '../../shared/constants/export-constants';
import { fileStatus } from '../../shared/constants/master-page.constants';
import { ConversionService } from '../../shared/services/conversion.service';
import { addHoursByTimeZone, generateTimeZoneOffsetString, subtractHoursByTimeZone } from '../../shared/utilities/timeZoneConverter';
import { sortByKeyFunc } from '../../shared/utilities/util';
import { State } from '../../state/container-states/app.state';
import { SignalRService } from 'src/app/services/signal-r.service';
var FileSaver = require('file-saver');

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})

export class ExportComponent implements OnInit, OnDestroy {
  configClariti = configClariti;
  modal: { open: boolean, type: string } = {
    open: false,
    type: ''
  };
  wellName: string;
  isLocal: boolean;
  sensors: any[] = [];
  LogId: string;
  selectedSensors: any[];
  inProgress = false;
  unsubscribe$ = new Subject();
  selectedDates: DateRange = {
    start: undefined,
    end: undefined
  };
  UseFixedWidth: boolean;
  exportForm: FormGroup;
  exportSensorList: IDataSensor[] = [];
  enableFixedWidthMessage: boolean;
  enableMissingFloatMessage: boolean;
  enableMissingIntMessage: boolean;
  DateTimeFormatOptions;
  DelimiterOptions;
  DecimalsOptions;
  jobDateRange: DateRange;
  startDateInvalid = false;
  endDateInvalid = false;
  selectedTimeZone: { [key: string]: string } = {};
  jobDateRangeConverted: DateRange;
  converterData: Conversion;
  exportDateRange: DateRange = {
    start: undefined,
    end: undefined
  };
  finalText: any;
  finalData: any;
  count: any;
  showExportPercentage = 0;
  selectedStartDate: any;
  selectedEndDate: any;
  showExportWaitIndicator = false;
  UnitProfile: string;
  timeZoneOffset: string;
  dayRangeResponse: any;
  wb: XLSX.WorkBook;
  ws: XLSX.WorkSheet;
  minMaxOverlay = false;
  multiWell: any;
  constructor(
    private exportService: ExportService,
    private store: Store<State>,
    private router: Router,
    private tempPressureService: PressureAndTemperatureService,
    private conversionService: ConversionService,
    private graphUtilService: GraphUtilService,
    private signalRService : SignalRService
    ) {
    this.exportService.clearStatusProgress.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.inProgress = false;
      });
  }

  ngOnInit() {
    //stop live readings signal r service if, export
    this.signalRService.stopConnection();
    localStorage.setItem('refreshFlag', '0');
    localStorage.setItem('component', 'export');
    this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState: any) => {
        if (conversionState) {
          this.selectedTimeZone.timeUnit = conversionState.conversion.timeUnit;
          this.selectedTimeZone.timeLabel = conversionState.conversion.timeLabel;
          this.converterData = conversionState.conversion;
          if (this.jobDateRange) {
            this.updatedJobDateRangeConverted();
            this.exportService.resetDates.next(true);
            this.timeZoneOffset = undefined;
          }
        }
      });

    if (this.configClariti.exports.exportsettings.Method === 'local') {
      this.isLocal = true;
      this.DateTimeFormatOptions = DateTimeFormatOptionsLocal;
    } else {
      this.isLocal = false;
      this.DateTimeFormatOptions = DateTimeFormatOptions;
    }
    this.DelimiterOptions = DelimiterOptions;
    this.DecimalsOptions = DecimalsOptions;
    this.buildNewForm();
  }

  ngOnDestroy() {
    const bkgd = document.getElementsByClassName('page-background');
    bkgd[0].scrollTop = 0;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get FixedWidth() { return this.exportForm.get('FixedWidth'); }
  get MissingFloatValue() { return this.exportForm.get('MissingFloatValue'); }
  get MissingIntValue() { return this.exportForm.get('MissingIntValue'); }

  onDateSelectionChanged(event: DateRange) {
    let startDate = event.start;
    let endDate = event.end;
    this.selectedStartDate = event.start;
    this.selectedEndDate = event.end;

    if (!this.jobDateRangeConverted) {
      return false;
    }

    if (startDate) {
      startDate = subtractHoursByTimeZone(
        new Date(event.start.valueOf()),
        this.selectedTimeZone.timeUnit
      );

      if (dateAsString(event.start) === dateAsString(this.jobDateRangeConverted.start)) {
        startDate = this.jobDateRange.start;
      }
    }

    if (endDate) {
      endDate = subtractHoursByTimeZone(
        new Date(event.end.valueOf()),
        this.selectedTimeZone.timeUnit
      );
      if (dateAsString(event.end) === dateAsString(this.jobDateRangeConverted.end)) {
        endDate = this.jobDateRange.end;
      }

      this.timeZoneOffset = generateTimeZoneOffsetString(
        endDate,
        this.selectedTimeZone.timeUnit
      );
    }

    this.selectedDates = {
      start: startDate,
      end: endDate
    };
    this.checkValidityOfDates();
  }

  updatedJobDateRangeConverted() {
    this.jobDateRangeConverted = {
      start: addHoursByTimeZone(
        new Date(this.jobDateRange.start.valueOf()),
        this.selectedTimeZone.timeUnit
      ),
      end: addHoursByTimeZone(
        new Date(this.jobDateRange.end.valueOf()),
        this.selectedTimeZone.timeUnit
      )
    };
  }

  checkValidityOfDates() {
    const dateInvalid = (date: Date): boolean => {
      return date && (date < this.jobDateRange.start || date > this.jobDateRange.end);
    };
    this.startDateInvalid = dateInvalid(this.selectedDates.start);
    this.endDateInvalid = dateInvalid(this.selectedDates.end);
  }

  toggleFixedWidthInput(bool: boolean) {
    if (bool) {
      if (this.FixedWidth.dirty) {
        this.enableFixedWidthMessage = true;
      }
      this.UseFixedWidth = true;
      this.exportForm.controls.FixedWidth.enable();
    } else {
      this.enableFixedWidthMessage = false;
      this.UseFixedWidth = false;
      this.exportForm.controls.FixedWidth.disable();
    }
  }

  enableMessage(messageToggleProperty: string) {
    this[messageToggleProperty] = true;
  }

  onFixedWidthChange() {
    if (this.FixedWidth.value.length > 1) {
      this.enableFixedWidthMessage = true;
    }
  }

  logout() {
    this.router.navigate(['login']);
  }

  onSubmit() {
    if(this.inProgress == true) {
      return;
    }
    let LogMnemonicList = [];
    let ExportCurveOrderModels = [];

    this.selectedSensors.forEach((sensor, i) => {
      const Decimals = this.exportForm.controls.sensorDecimals.value[i];

      this.exportSensorList.push({
        uid: sensor.multiLogId,
        multiLogId: sensor.multiLogId,
        uidWell: this.exportForm.controls.UidWell.value,
        sensorName: sensor.sensorName,
        unitType: sensor.unitType,
        displayName: sensor.sensorOriginalName
      });

      let MnemonicListItem = {
        Mnemonic: sensor.sensorName,
        TraceLabel: sensor.displayName,
        Decimals,
        DataType: sensor.dataType,
      };

      let MnemonicList = [];
      /* if the logId of the sensor exists already in the LogMnemonicList,
        append the MnemonicListItem to existing MnemonicList  else create
        a new  the LogMnemonicList item with the new LogId*/
      const itemIndex = LogMnemonicList.findIndex(m => m.LogId === sensor.multiLogId);
      if (itemIndex === -1) {
        MnemonicList.push(MnemonicListItem);
        LogMnemonicList.push({
          LogId: sensor.multiLogId,
          MnemonicList,
          CoercionSetting: null
        })
      }
      else {
        LogMnemonicList[itemIndex].MnemonicList.push(MnemonicListItem);
      }
      // add the unique MnemonicItems to the export curve order Models
      ExportCurveOrderModels.push(MnemonicListItem);
    });
    let formPayload = {
      JobName: this.exportForm.controls.JobName.value,
      UidWell: this.exportForm.controls.UidWell.value,
      IsAsciiVariableDisplay: true,
      LogMnemonicList,
      IndexType: 0, // 0 for time-based log, 3 for depth-based log
      DateTimeFormat: this.exportForm.controls.DateTimeFormat.value,
      IndexValueDecimalPlaces: 2, // not needed for time-date
      StartDateTimeIndex: this.selectedDates.start ? this.selectedDates.start.toISOString() : '',
      EndDateTimeIndex: this.selectedDates.end ? this.selectedDates.end.toISOString() : '',
      ExportType: 0, // Exporter file format type: 0 = ASCII, 1 = DLIS, LAS 2.0 3 etc.
      Delimeter: this.exportForm.controls.Delimiter.value, // this is not a typo - needs to be misspelled
      HeaderValueDecimalPlaces: 6, // missing input in decimal places when you click header
      MissingFloatValue: +this.exportForm.controls.MissingFloatValue.value,
      MissingFloatDecimalPlaces: 2, // missing input - this comes from "decimal settings missing values in IMW"
      MissingIntValue: +this.exportForm.controls.MissingIntValue.value,
      MissingAsciiValue: this.exportForm.controls.MissingAsciiValue.value,
      Comments: warrantyMessage + this.exportForm.controls.Comments.value,
      // UnitProfile: 'English|00000000-0000-0000-0000-000000000001',
      UnitProfile: this.UnitProfile,
      IncludeHeader: true, // true by default
      WrapMode: 0, // applicable for LAS 2.0. Max 20 curves can be exported. If more than 20 needed they can be wrapped to next line
      UseFixedWidth: this.UseFixedWidth,
      ExportCurveOrderModels,
      TimeZone: this.timeZoneOffset
    };

    if (this.UseFixedWidth) {
      formPayload = Object.assign(formPayload, { FixedWidth: +this.exportForm.controls.FixedWidth.value });
    }

    if (!this.isLocal) {
      this.inProgress = true;
      this.exportService.exportBegun.emit({
        jobName: this.exportForm.controls.JobName.value,
        fileId: "",
        started: false
      });
      this.exportService.submitExportRequest(formPayload).pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          this.inProgress = true;
          this.exportService.exportBegun.emit({
            jobName: this.exportForm.controls.JobName.value,
            fileId: response,
            started: true
          });
        },
        (()=>{
          this.inProgress = false;
        }));
    } else {
      if (localStorage.length === 0) {
        this.logout();
        return false;
      } else {
        this.exportRawDataToExcel(formPayload);
      }
    }
  }

  assignDecimalToData(logs) { // Assign corresponding decimal precision or export
    let count = 0;
    logs.forEach((log) => {
      count++;
      log[0]['decimalPlace'] = this.exportForm.controls.sensorDecimals.value[count - 1];
    });
    return logs;
  }

  getValueForTime(key, array) {
    if (_.find(array, (o) => o[0] === key)) {
      return _.find(array, (o) => o[0] === key)[1];
    } else {
      return '----';
    }
  }

  exportRawDataToExcel(formPayload) {
    const text1 = '~Well Information Block  \n' +
      '#MNEM.UNIT       Data Type    Information \n' +
      '#---------     -----------    ---------------------------\n' +
      ' STRT.S          1556321401.00: START TIME ' + moment(this.selectedStartDate).utcOffset(0, true).format('HH:mm:ss DD-MMM-YY') + '\n' +
      ' STOP.S          1557358196.00: STOP TIME  ' + moment(this.selectedEndDate).utcOffset(0, true).format('HH:mm:ss DD-MMM-YY') + '\n' +
      ' STEP.S                   0.00: STEP TIME  \n' +
      ' NULL.               -999.2500: NULL VALUE  \n' +
      ' FLD .              FIELD NAME:  VOFTest  \n' +
      ' WELL.               WELL NAME:  ' + this.wellName + '  \n' +
      ' API .              API NUMBER:  sefs  \n' +
      ' COMP.                 COMPANY:  Halliburton  \n' +
      ' SPUD.               SPUD DATE:  NA  \n' +
      ' WDMS.ft               WD ELEV:  0.000000  \n' +
      ' LATI.deg             LATITUDE:  NA  \n' +
      ' LONG.deg            LONGITUDE:  NA  \n' +
      '#  ';
    const text2 = warrantyMessage;

    // tslint:disable-next-line:prefer-const
    let sensorList = this.exportSensorList;

    this.showExportWaitIndicator = true;

    const startDate = moment(this.selectedStartDate);
    const endDate = moment(this.selectedEndDate);
    const tempStartDate = moment(startDate).add(0, 'days').toDate();
    let percentage = 0;
    let result = endDate.diff(startDate, 'days');
    if (result === 0) {
      result = 1;
    }
    this.showExportPercentage = 0;
    this.exportDateRange = {
      start: tempStartDate,
      end: moment(tempStartDate).add(1, 'days').toDate()
    };
    this.finalData = '';
    this.finalText = '';
    this.count = 0;
    this.dayRangeResponse = 0;
    let inBetweenTxt = '';
    if (this.UseFixedWidth) {
      inBetweenTxt = ' '.repeat(this.exportForm.controls.FixedWidth.value);
    }
    else {
      inBetweenTxt = formPayload['Delimeter'];
    }

    let dateTimeFormatLocal = formPayload['DateTimeFormat'];

    this.DateTimeFormatOptions.forEach((option) => {
      if (option.label === dateTimeFormatLocal) {
        dateTimeFormatLocal = option.value;
      }
    });
    for (let i = 0; i <= result; i++) {
      setTimeout(() => {
        this.tempPressureService
          .joinDataForAllWells(sensorList, this.exportDateRange, null)
          .subscribe((logs: any) => {
            if (logs.length > 0) {
              logs = this.assignDecimalToData(logs);
              this.count = this.count + 1;
              percentage = Math.round(100 / result) * this.count;
              this.dayRangeResponse = (percentage > 100) ? 100 : percentage;
              const finalDataSource = this.graphUtilService.buildSensorDataforChart(logs);
              const itemArray = [];
              let sensorItemList = '';
              for (const sensorData of finalDataSource) {
                const unitName = this.converterData[sensorData.typeService.toLowerCase() + 'Unit'];
                sensorItemList = sensorItemList + sensorData['sensorName'] + '(' + unitName + ')' + inBetweenTxt;
                const data = [];
                for (const item of sensorData.logData['data']) {
                  item[0] = item[0]; //this.conversionService.convertTime(this.converterData.timeUnit, item[0]);
                  if ((sensorData.typeService).toLowerCase() === 'pressure') {
                    item[1] = this.conversionService.convertExportPressure(this.converterData.pressureUnit, item[1]).toFixed(sensorData.decimalPlace);
                  } else {
                    item[1] = this.conversionService.convertExportTemperature(this.converterData.temperatureUnit, item[1]).toFixed(sensorData.decimalPlace);
                  }
                  data.push(item);
                }
                itemArray.push(data);
              }
              this.finalText = text1 + text2 + '\n' + this.exportForm.controls.Comments.value + '\n' + 'Time & Date(SEC)' + inBetweenTxt + sensorItemList;
              if (itemArray[0]) {
                // this.createExportData(itemArray);
                let timeArray = [];
                for (let i = 0; i < itemArray.length; i++) {
                  timeArray = _.concat(timeArray, _.map(itemArray[i], 0));
                }
                timeArray = _.sortBy(_.uniq(timeArray));

                for (let j = 0; j < timeArray.length; j++) {
                  let txt = inBetweenTxt;
                  for (let k = 0; k < itemArray.length; k++) {
                    txt = txt + this.getValueForTime(timeArray[j], itemArray[k]) + inBetweenTxt;
                  }
                  this.finalData = this.finalData + moment(timeArray[j]).format(dateTimeFormatLocal) + txt + '\n';
                }
              }
              if ((this.count - 1) === result) {
                this.showExportPercentage = 99;
                setTimeout(() => {
                  this.saveFile();
                  this.showExportWaitIndicator = false;
                }, 800);
              } else {
                this.showExportPercentage = (this.count / result) * 100;
              }
            }
          });
        const tempEndDate = moment(this.exportDateRange.end).add(1, 'days').toDate();
        this.exportDateRange = {
          start: this.exportDateRange.end,
          end: tempEndDate,
        };
      }, 2500 * i);
    }
  }

  saveFile() {
    const blob = new Blob([this.finalText + '\n' + this.finalData], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'data.asc');
    this.showExportWaitIndicator = false;
    this.finalData = '';
    this.exportSensorList = [];
  }

  getDecimal(sensorName) {
    const tempArray = this.exportSensorList.filter((sensor) => {
      return sensor['sensorName'] === sensorName;
    });
    return tempArray[0]['decimal'];
  }

  buildNewForm() {
    let jobName = '';
    let uidWell = '';

    if (this.exportForm) {
      const { JobName, UidWell } = this.exportForm.controls;
      jobName = JobName.value;
      uidWell = UidWell.value;
    }

    this.exportForm = new FormGroup({
      DateTimeFormat: new FormControl(this.isLocal ? 'MM/DD/YY HH:mm:ss' : 'MM/DD/YY HH:mm:SS'),
      UseFixedWidth: new FormControl(false),
      Delimiter: new FormControl('	'),
      FixedWidth: new FormControl(
        {
          value: null,
          disabled: !this.UseFixedWidth,
        },
        { validators: [Validators.min(19), Validators.max(999)] }
      ),
      MissingFloatValue: new FormControl(-999.25, {
        validators: [
          Validators.min(-9999999),
          Validators.max(9999999),
          this.missingValueValidator()
        ]
      }),
      MissingIntValue: new FormControl(-999, {
        validators: [
          Validators.min(-9999999),
          Validators.max(9999999),
          this.missingValueValidator()
        ]
      }),
      MissingAsciiValue: new FormControl(''),
      Comments: new FormControl(''),
      JobName: new FormControl(jobName, Validators.required),
      UidWell: new FormControl(uidWell, Validators.required),
      sensorDecimals: new FormArray([])
    });

    this.selectedDates = {
      start: undefined,
      end: undefined
    };
    this.exportService.resetDates.next(true);
    this.selectedSensors = [];
    this.enableFixedWidthMessage = false;
    this.enableMissingFloatMessage = false;
    this.enableMissingIntMessage = false;
    this.startDateInvalid = false;
    this.endDateInvalid = false;
    this.UseFixedWidth = false;
  }

  missingValueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value === '-' || control.value === '';
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }

  openModal(id: string) {
    this.modal.type = id;
    this.modal.open = !this.modal.open;
  }

  closeModal() {
    this.modal.open = !this.modal.open;
    this.modal.type = '';
  }
  getMultipleId(well) { // Function to get unique multiple log id for export from list of sensors
    const result = _(well.sensors)
      .groupBy((x) => x.multiLogId)
      .map((value, key) => ({
        multiLogId: key,
      }))
      .value();
    return _.uniq(result);
  }

  handleWellSelection(
    data: {
      wellName: string,
      sensors: any[],
      uid: string,
      startDateTimeIndex: string,
      endDateTimeIndex: string
    }
  ) {
    this.multiWell = this.getMultipleId(data);
    if (data.wellName !== this.wellName) {
      this.buildNewForm();
      this.wellName = data.wellName;
      this.sensors = sortByKeyFunc(data.sensors, 'sensorOriginalName');
      this.LogId = data.uid;
      this.minMaxOverlay = true;
      if (this.isLocal) {
        if (localStorage.length == 0) {
          this.logout();
          return false;
        } else {
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          const userWellList = currentUser['IWJob'];
          const well = userWellList.find((well) => {
            return this.wellName === well.WellName;
          });
          this.jobDateRange = {
            start: new Date(well['WellCreatedDate'] + 'Z'),
            end: new Date()
          };
          this.updatedJobDateRangeConverted();
          this.minMaxOverlay = false;
        }
      } else {
        this.exportService.getStartEndTime({ uidWell: this.exportForm.controls.UidWell.value, logId: this.multiWell })
          .pipe(first()).subscribe((response: any) => {
            const { maxIndex, minIndex } = response.response;
            this.jobDateRange = {
              start: new Date(minIndex + 'Z'),
              end: new Date(maxIndex + 'Z')
            };
            this.updatedJobDateRangeConverted();
            this.minMaxOverlay = false;
          }, () => {
            this.minMaxOverlay = false;
          });
        this.checkForUnfinishedExport(data.wellName);
      }

      let jobList = JSON.parse(localStorage.currentUser).IWJob;


      this.UnitProfile = jobList.find((job) => job.jobName === this.exportForm.controls.JobName.value).UnitSet;
    }

    this.closeModal();
  }

  checkForUnfinishedExport(wellName: string) {
    this.exportService.getExportStatus(this.exportForm.controls.JobName.value)
      .subscribe((response) => {
        const toBeHandled = response.filter((status) => {
          return status.ExportStatus !== fileStatus.done;
        });
        if (toBeHandled && toBeHandled.length) {
          this.inProgress = true;
          this.exportService.transmitStatus.emit(toBeHandled);
        }
        this.wellName = wellName;
      });
  }

  handleSensorSelection(sensorList: any[]) {
    this.selectedSensors = sortByKeyFunc(sensorList, 'sensorOriginalName');
    const sensorDecimals = this.exportForm.get('sensorDecimals') as FormArray;

    this.sensors.forEach(() => {
      sensorDecimals.push(new FormControl(2));
    });

    this.closeModal();
  }

  getLabel(sensorType:string){
    return this.graphUtilService.getUnitLabelSensorType(sensorType.toLowerCase());
  }

  submitDisabled() {
    return this.exportForm.invalid ||
      !this.selectedDates.start ||
      !this.selectedDates.end ||
      this.startDateInvalid ||
      this.endDateInvalid ||
      this.selectedSensors.length === 0;
  }

  resetDisabled() {
    return this.exportForm.pristine &&
      !this.selectedDates.start &&
      !this.selectedDates.end &&
      this.selectedSensors.length === 0;
  }
}
