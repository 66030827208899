import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ChartStoreService } from 'src/app/services/chart.service';
import { PressureAndTemperatureService } from 'src/app/services/pressure-temperature.service';
import { State } from 'src/app/state/container-states/app.state';
import { ERR_MESSAGES } from '../shared/constants/error-messages.constants';
import { clearLocalStorage } from '../shared/utilities/util';
import { AlertService } from './alert.service';
import { SignalRService } from './signal-r.service';
import { WellService } from './well.service';
import { OktaAuthService } from '@okta/okta-angular';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  remember: string;
  isAuthenticated: boolean = false;
  private authStateSource = new BehaviorSubject<boolean>(false);
  currentAuthState = this.authStateSource.asObservable();
  constructor(
    @Inject('ENV_CONFIG') private envConfig: any,
    private oktaAuth: OktaAuthService,
    private chartStoreService: ChartStoreService,
    private http: HttpClient,
    private wellService: WellService,
    private alertService: AlertService,
    private signalRService: SignalRService,
    private tempPressureService: PressureAndTemperatureService,
  ) { 
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean)  => {
        this.isAuthenticated = isAuthenticated;
        this.changeAuthState(this.isAuthenticated);
      }
    );

  }
  changeAuthState(authState: boolean){
    this.authStateSource.next(authState);
  }

  login(username: string, password: string) {
    const loggedInUser = JSON.parse(localStorage.getItem('currentUser'));
    clearLocalStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(username + ':' + password),
      }),
      secureProtocol: "TLSv1_2_method"
    };
    return this.http.get(this.envConfig.api.newWAIAuth, httpOptions)
      .pipe(map((user) => {
        if (loggedInUser !== null && loggedInUser.email !== user['email']) {
          localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
          throw new Error(ERR_MESSAGES.USER_ALREADY_LOGGEDIN);
        }
        if (user && user['accessToken']) {
          user['sessionStartTime'] = (new Date()).getTime();
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('localSiteFlag', '0');
        } else {
          throw new Error(ERR_MESSAGES.USER_NO_DATA);
        }
      }));
  }

  //LSS
  /*loginToDevices(userDetail: object, username: string) {
    clearLocalStorage();
    return this.http.post(this.envConfig.api.authorization, userDetail)
      .pipe(map((user) => {
        if (user && user['Access_Token']) {
          user['sessionStartTime'] = (new Date()).getTime();
          user['accessToken'] = user['Access_Token'];
          user['Email'] = username;
          user['IWJob'] = [];
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('localSiteFlag', '1');

          this.wellService.getWells().subscribe((res: any) => {
            if (res && res.response) {
              const wells = res.response.well;
              _.map(wells, (well) => {
                if (well.uid) {
                  user['IWJob'].push({
                    AllLogs: true,
                    Customer: 'XXXX',
                    JobName: 'XXX',
                    LogUids: [],
                    PSLName: 'XXX',
                    UnitSet: 'Clariti_English',
                    WellName: well.name,
                    WellUid: well.uid,
                    WellCreatedDate: well['commonData']['dTimCreation']
                  });
                }
              });
            }
            localStorage.setItem('currentUser', JSON.stringify(user));
          });

        }
        else {
          if (user['errorCode'] === 106) {
            throw new Error(ERR_MESSAGES.INVALID_USER_PASSWORD);
          } else if (user['errorCode'] === 107) {
            throw new Error(ERR_MESSAGES.TOKEN_EXPIRED);
          } else {
            throw new Error(user['errorMessage']);
          }
        }
      }));
  }*/

  /*refreshToken() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post(this.envConfig.api.refreshToken, { token: user.waiToken });
  }*/

  logout() {
    if (this.remember) {
      localStorage.removeItem('currentUser');
    }
  }

  async logoutCompletelyRedirectToLogin() {
    this.alertService.stopAlertTimer();
    this.chartStoreService.stopLivePool();
    this.signalRService.stopConnection();
    this.alertService.stopBatteryTimer();
    this.tempPressureService.changeExportStatus('close')
    this.tempPressureService.discardExport();

    this.http.post(`${this.envConfig.oktaBaseUrl}/oauth2/default/v1/revoke`, { token: this.oktaAuth.tokenManager.get("refreshToken"), token_type_hint: "refresh_token" });
    this.oktaAuth.tokenManager.clear();
    const options = { postLogoutRedirectUri: window.location.origin, revokeAccessToken: true,  accessToken: { accessToken: this.oktaAuth.tokenManager.get("accessToken") } };
    this.oktaAuth.signOut(options);
    clearLocalStorage();
  }

  getAccessToken(){
    return this.oktaAuth.getAccessToken();
  }

  getUOMProfiles() {
    localStorage.userProfile = this.envConfig.uomProfiles;
  }
}
