import { Injectable } from '@angular/core';
import { IDataBase } from 'jsstore';
import * as dbTable from '../../constants/db-tables.constants';
import { DbConnectionService } from './db-connection.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  dbName = 'vof_db';
  isUpgraded = true;

  constructor() {
    this.connection.setLogStatus(false);
    this.initJsStore();
  }

  get connection() {
    return DbConnectionService.idbCon;
  }

  initJsStore() {
    this.connection.getDbVersion(this.dbName).then((version) => {
      if (version === dbTable.userPrefTable.version) {
        this.isUpgraded = false;
      }
    });
    this.connection.isDbExist(this.dbName).then((isDbExist) => {
      if (isDbExist && this.isUpgraded === false) {
        this.connection.openDb(this.dbName);
      } else {
        const db = this.getDatabase();
        db.tables.forEach((table) => {
          this.connection.clear(table.name);
        });
        localStorage.clear();
        this.connection.createDb(db);
      }
    });
  }

  public getDatabase() {
    const dataBase: IDataBase = {
      name: this.dbName,
      tables: [
        dbTable.userPrefTable,
        dbTable.globalFilterTable,
        dbTable.unitConversionTable,
        dbTable.chartSettingsTable
      ],
    };
    return dataBase;
  }
}
