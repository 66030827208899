import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SignalRService } from 'src/app/services/signal-r.service';
import { IAlert } from '../../models/alert';
import { ChartStoreService } from '../../services/chart.service';
import { WellService } from '../../services/well.service';
import * as fromAlert from '../../state/container-states/alert.state';
import { State } from '../../state/container-states/app.state';
import { Conversion } from 'src/app/models/converterData';
import { ConversionService } from '../../shared/services/conversion.service';

@Component({
  selector: 'app-schematics',
  templateUrl: './schematics.component.html',
  styleUrls: ['./schematics.component.scss'],
  providers: [MessageService]
})
export class NewSchematicsComponent implements OnInit, OnDestroy {

  @ViewChild('myCanvas') myCanvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public innerPipeColor = '#808080';
  public alerts: IAlert[] = [];
  wells: any = [];
  sensorColors = {};
  sensorColorCodes = {
    red: '#cc0000',
    green: '#00ff00'
  };
  globalWells: any [];
  image: any;
  horizontalConversionFactor: any; // used to draw horizontal well
  verticalConversionFactor: any; //used to draw vertical well
  verticalOfHorizontalConversionFactor: any; //used to draw the vertical part of a horizontal well
  selectedWell: any;
  mapSelectedWell: any;
  globalSelectedWells: any [];
  sensors: any = [];
  showMessage = false;
  showErrorMessage = false;
  showSelection = false;
  showWaitIndicator = false;
  isVertical =  true;
  unsubscribe$ = new Subject();
  configObj: any;
  sdata: any;
  messageText = '';
  pipeLength = 900;
  formattedSensorData: any;
  hoverGauge: any;
  kickOffPoint = 0;
  isapiInprogress = true; //to wait for the nickname calls along with wells and sensors to complete
  converterData:Conversion;
  @Input() schematic: any;
  @Output() activeAlerts = new EventEmitter<{ alertTriggered: boolean, alertLength: number, alertList: any }>();
  @Output() schematicSelectedWell: EventEmitter<any> = new EventEmitter();

  isSimulatedData = false;
  count: any = 0;
  uidWell = ' ';
  constructor(private formStore: Store<fromAlert.AlertPageState>, private wellService: WellService,
              private store: Store<State>, private http: HttpClient,
              private chartStoreService: ChartStoreService,
              private signalRService: SignalRService,
              private conversionService: ConversionService,
              private messageService: MessageService) {
  }

  getStaticData(): Observable<any> {
    // get users from api
    return this.http.get('assets/data/schematic.json').pipe(
      tap((data) => {
        return data.json; })
    );
  }

  ngOnInit() {
    localStorage.setItem('refreshFlag', '0');
    localStorage.setItem('component', 'newSchematic');
    let gauge = '';
    let wellID = '';
    this.chartStoreService.changeColorMapping([]);
    if (this.selectedWell) {
      this.getWellBoreData(this.selectedWell);
    }

    this.signalRService.isSchematicHovered.pipe(takeUntil(this.unsubscribe$))
    .subscribe((hoveredItem) => {
      this.schematicUpdate(hoveredItem);
    });

    this.formStore
      .pipe(select(fromAlert.getAlertRules), takeUntil(this.unsubscribe$))
      .subscribe((alerts: IAlert[]) => {
        if (alerts) {
          this.sensorColors = {};
          /*
           * Set color of the sensors based on alarm
           *
           */
          _.map(alerts, (alert) => {
            const sensorSplit = alert.AlarmDetail.MnemonicAlias.split(new RegExp('[_.]', 'g'));

            //To handle new sensor tag format
            if (sensorSplit.length >= 6){
              gauge = `${sensorSplit[1]}_${sensorSplit[3]}`.toUpperCase();
            }
            else if (sensorSplit.length == 5){
              gauge = `${sensorSplit[1]}_${sensorSplit[2]}`.toUpperCase();
            } else {
              gauge = sensorSplit.pop().replace(/pres|temp/gi, '').toUpperCase();
            }

            wellID = alert.UidWell;

            if (!this.sensorColors[wellID]) {
              this.sensorColors[wellID] = {};
            }

            if (alert.AlarmDetail.NotificationStatus || alert.AlarmDetail.AlarmNotificationStatus) {
              this.sensorColors[wellID][gauge] = 'red';
              this.activeAlerts.emit({alertTriggered: true, alertLength: alerts.length, alertList: alerts});
            } else {
              if (!this.sensorColors[wellID][gauge]) {
                this.sensorColors[wellID][gauge] = 'green';
              } else if (this.sensorColors[wellID][gauge] !== 'red'){
                this.sensorColors[wellID][gauge] = 'green';
              }
            }
          });
          this.drawSchematics(null);
        }
      });

    /**
     * Dimensions used for drawing schematic
     */
    this.configObj = {
      topSpace: 50,
      leftSpace: 200,
      totalWidth: 300,
      innerPipeDiameter: 30,
      canvasHeight : 635, //set initial height of canvas
      canvasWidth: 750, //set initial width of canvas
      // Multiply wth conversion factor
      innerPipeLength: 0, // total pipe length
      verticalPipeLength: 0, //vertical pipe length of horizontal well
      horizontalPipeLength: 0, //horizontal pipe length of horizontal well
      tvd: 0,
      md: 0,
    };

    this.context = (this.myCanvas.nativeElement as HTMLCanvasElement).getContext('2d');
    //For responsiveness of schematics
    const query = window.matchMedia('(orientation:portrait)');
    if (query.matches === true) {
      if ((window.innerHeight === 1024 && (window.innerWidth === 768 || window.innerWidth === 600)) || (window.innerWidth === 1024 && window.innerHeight === 1366) ||
       (window.innerWidth === 1668 && window.innerHeight === 2388)) //Ipad
      {
        (this.myCanvas.nativeElement).height = 1500;
        (this.myCanvas.nativeElement).width = 1000;
        this.configObj.canvasHeight = 1300;
        this.configObj.innerPipeLength = 21000;
      }
      if ((window.innerWidth >= 320 && window.innerWidth <= 600) && (window.innerHeight >= 533 && window.innerHeight <= 960))
       {
        (this.myCanvas.nativeElement).height = 1100;
        (this.myCanvas.nativeElement).width = 1000;
        this.configObj.canvasHeight = 1000;
        this.configObj.innerPipeLength = 20000;
      }
      if (navigator.userAgent.match(/iPhone/g)) //iPhone
      {
        (this.myCanvas.nativeElement).height = 1100;
        (this.myCanvas.nativeElement).width = 1000;
        this.configObj.canvasHeight = 1000;
        this.configObj.innerPipeLength = 20000;
      }
      if (window.innerWidth === 800 && window.innerHeight === 1280) //Kindle
      {
        (this.myCanvas.nativeElement).height = 1800;
        (this.myCanvas.nativeElement).width = 800;
        this.configObj.canvasHeight = 1700;
        this.configObj.innerPipeLength = 21000;
      }
      this.drawSchematics(null);
    }
    this.globalSelectedWells = [];
    // Lint-justification: select is used though it is deprecated just to align with same throughout the application
    this.store.select((state: State) => state.globalFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((wellState) => {
        this.globalWells = wellState ? wellState.wells : [];
        this.showWaitIndicator = true;
        this.isapiInprogress = wellState.apiInprogress;
          if (!this.isapiInprogress) {
          
        // Check for global selections
        if (this.globalWells.length > 0) {
          if (_.some(this.globalWells, { checked: true})) {
            this.showMessage = false;
            this.showErrorMessage = false;
            this.updateGlobalSelectedWell(this.globalWells);
            this.selectedWell = this.getSelected();
            this.getWellBoreData(this.selectedWell);
            //Show dropdown only if selected sensors are more than 1
            if (this.globalSelectedWells) {
              this.globalSelectedWells.length === 1 ? this.showSelection = false : this.showSelection = true;
            }

          }
          else {
            this.messageText = 'No well selected.';
            this.showMessage = true;
            this.showWaitIndicator = false;
          }
        }
      }
      });

      this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        
        if (conversionState) {
          this.converterData = conversionState.conversion;
          this.drawSchematics(null);
        }
        });
  }

  /**
   * Returns the selected well , Takes care of the global well selection in combination with local selection
   */
  getSelected() {
    if (this.selectedWell) {
      return _.some(this.globalSelectedWells, {uidWell: this.selectedWell.uidWell}) ? this.selectedWell : this.globalSelectedWells[0];

    }
    return this.globalSelectedWells[0];

  }


  getWellBoreData(selectedWell){
    this.schematicSelectedWell.emit(this.selectedWell);
    let run = selectedWell.wellName ? selectedWell.wellName.toUpperCase() : null;
    let record = "OBJECTHIERARCHY";
    this.wellService.getWellDataSetProperties(selectedWell.uidWell, run, record).subscribe((result: any) => {
      this.messageService.clear();
      this.showMessage = false;
      this.showErrorMessage = false;
      if (result) {
        let dataSetProperties = result.response.dataSetProperties && result.response.dataSetProperties.length > 0 ? result.response.dataSetProperties[0] : null;
        if(dataSetProperties){

        const wellData = result.response.dataSetProperties && result.response.dataSetProperties.length >0 ? JSON.parse(dataSetProperties.data[0].value).Well : null;
        let wellMeasuredDepth = wellData.Wellbore.MD.Value;
        let wellTotalVerticalDepth = wellData.Wellbore.TVD.Value;
        if (!wellMeasuredDepth) {
          this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
          this.messageText = 'No WellBore Data for Selected Well';
          this.showMessage = true;
          this.showWaitIndicator = false;
          return;
        }

        this.configObj.md = this.conversionService.convertDepth(this.converterData.depthUnit, wellMeasuredDepth);
        this.configObj.tvd = this.conversionService.convertDepth(this.converterData.depthUnit, wellTotalVerticalDepth);
        // this.updateConfigWithWellboreData(wellBoreData);
        const selectedWellData = _.find(this.globalWells, { uidWell: selectedWell.uidWell });
        if (selectedWellData.sensors && selectedWellData.sensors.length > 0) {
           this.sensors = selectedWellData.sensors;
          // const sensorRequestData = this.getSensorRequestObjects(this.sensors);
          // this.getSensorData(sensorRequestData);

          // this.formattedSensorData = this.getDataForSchematicFormat(result)
          // this.formattedSensorData = this.formatDataForNegativeCase(this.formattedSensorData);
          this.formattedSensorData = this.getDataFormattedForSchematic(wellData);
          this.formattedSensorData = this.formatDataForNegativeCase(this.formattedSensorData);
          this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
          this.drawSchematics(null);
          this.showWaitIndicator = false;

        } else {
          this.messageText = 'No sensor data available for selected well';
          this.showMessage = true;
          this.showWaitIndicator = false;
          this.formattedSensorData = null;
        }

    }else{
      this.getWellBoreData1(selectedWell);
    }
  }
    }, () => {
    });
  }

  /**
   * Gets well bore data and prepare for getting sensor data, If no well bore data is present shows message
   * @param selected
   */
  getWellBoreData1(selectedW) {
    this.schematicSelectedWell.emit(this.selectedWell);
    this.wellService.getWellBore(selectedW.uidWell).subscribe((result: any) => {
      this.messageService.clear();
      this.showMessage = false;
      this.showErrorMessage = false;
      if (result) {
        const wellBoreData = result.response.wellbore ? result.response.wellbore : null;
        if (!wellBoreData[0].md) {
          this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
          this.messageText = 'No WellBore Data for Selected Well';
          this.showMessage = true;
          this.showWaitIndicator = false;
          return;
        }
        this.updateConfigWithWellboreData(wellBoreData);
        const selectedWellData = _.find(this.globalWells, { uidWell: selectedW.uidWell });
        if (selectedWellData.sensors && selectedWellData.sensors.length > 0) {
          this.sensors = selectedWellData.sensors;
          const sensorRequestData = this.getSensorRequestObjects(this.sensors);
          this.getSensorData(sensorRequestData);
        } else {
          this.messageText = 'No sensor data available for selected well';
          this.showMessage = true;
          this.showWaitIndicator = false;
          this.formattedSensorData = null;
        }
      }
    }, () => {
    });
  }

  /**
   * Updates the config object by the dimesions we get from well bore
   * @param wellBoreData
   */
  updateConfigWithWellboreData(wellBoreData) {
    const md = wellBoreData ? wellBoreData[0].md : null;
    this.configObj.md = md.value;
    const tvd = wellBoreData ? wellBoreData[0].tvd : null;
    this.configObj.tvd = tvd.value;
  }

  /**
   * Gets the senor data for all the sensors and draws schematic
   * @param sensorRequestData
   */
  getSensorData(sensorRequestData){
    this.uidWell = sensorRequestData[0].uidWell; //for alerts we are using the uid of the well
    this.wellService.getRawSensorData(sensorRequestData).subscribe((result: any) => {
      this.formattedSensorData = this.getDataForSchematicFormat(result)
      this.formattedSensorData = this.formatDataForNegativeCase(this.formattedSensorData);
      this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
      this.drawSchematics(null);
      this.showWaitIndicator = false;
    });
  }

  drawSchematics(hoverGauge) {
    if (this.context) {
      this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
      this.configObj.topSpace = 50;
      this.hoverGauge = hoverGauge ? hoverGauge : null;
      if (this.formattedSensorData) {
        try{
        if (this.formattedSensorData.length <= 0) { //check if selected well has sensor data
          this.messageText = 'No sensor data available for selected well';
          this.showErrorMessage = true;
        } else {
          this.showErrorMessage = false;
        }
        if (this.configObj.md == 0 && this.configObj.tvd == 0) //check if selected well has data
        {
          this.messageText = 'No well data';
          this.showErrorMessage = true;
        }
        else if (Number(this.configObj.md) > Number(this.configObj.tvd)) {
          this.createHorizontalSchematics(this.formattedSensorData);
        } else {
          this.createVerticalSchematics(this.formattedSensorData);
        }
      }catch(err){
        this.messageText = 'No well data';
        this.showErrorMessage = true;
      }
      }
    }
  }

  drawLegend() {
    this.context.fillStyle = '#00ff00';
    this.context.fillRect(this.myCanvas.nativeElement.width - 140, 20, 10, 10);
    this.context.font = '12px Arial';
    this.context.fillStyle = '#000000';
    this.context.fillText('Alert added /', this.myCanvas.nativeElement.width - 120, 30);
    this.context.fillText('acknowledged', this.myCanvas.nativeElement.width - 120, 45);

    this.context.fillStyle = '#cc0000';
    this.context.fillRect(this.myCanvas.nativeElement.width - 140, 60, 10, 10);
    this.context.font = '12px Arial';
    this.context.fillStyle = '#000000';
    this.context.fillText('Alert triggered', this.myCanvas.nativeElement.width - 120, 70);

    this.context.fillStyle = '#66cbea';
    this.context.fillRect(this.myCanvas.nativeElement.width - 140, 90, 10, 10);
    this.context.font = '12px Arial';
    this.context.fillStyle = '#000000';
    this.context.fillText('Hover on Live reading', this.myCanvas.nativeElement.width - 120, 100);

    this.context.fillStyle = '#808080';
    this.context.fillRect(this.myCanvas.nativeElement.width - 140, 120, 10, 10);
    this.context.font = '12px Arial';
    this.context.fillStyle = '#000000';
    this.context.fillText('No Alert Added', this.myCanvas.nativeElement.width - 120, 130);
  }

  schematicUpdate(data) {
    if (data && data.sensorOriginalName) {
      const sensorSplit = data.sensorOriginalName.split(new RegExp('[_.]', 'g'));
      if (sensorSplit.length >= 6) {
        this.drawSchematics(`${sensorSplit[1]}_${sensorSplit[3]}`.toUpperCase());
      } else if (sensorSplit.length == 5 ) {
        this.drawSchematics(`${sensorSplit[1]}_${sensorSplit[2]}`.toUpperCase());
      }else {
        this.drawSchematics(sensorSplit.pop().replace(/pressure|temperature|pres|temp/gi, '')); //To-do: Filter dynamically based on config
      }
    } else if (!data) {
      this.drawSchematics(null);
    }
  }

  formatDataForNegativeCase(data){
    if (this.isAllSensorMdTvdPresent(data)) {
      return data;
    } else {
      return this.modifySensorMdTvd(data);
    }
  }

  modifySensorMdTvd(data){
    this.isSimulatedData = true;
    // case when md and tvd not present for all the sensors
    if (this.isAllSensorMdTvdNotPresent(data)){
      this.configObj.tvd = this.configObj.md;
      for (let i = 0; i < data.length; i++){
        if (!this.isMdTvdPresent(data[i])){
          data[i].md = (this.configObj.md / (data.length + 1)) * (i + 1);
          data[i].tvd = (this.configObj.tvd / (data.length + 1)) * (i + 1);
        }
      }
    }

    //Case when md and tvd not present for first sensor
    if (!this.isMdTvdPresent(data[0])){
      data[0].md = data[1].md / 2;
      data[0].tvd = data[1].tvd / 2;
    }

    // case when md and tvd not present for a sensor in between
    for (let i = 1; i < data.length - 1; i++){
      if (!this.isMdTvdPresent(data[i])){
        data[i].md = (data[i + 1].md  - data[i - 1].md) / 2 + data[i - 1].md;
        data[i].tvd = (data[i + 1].tvd  - data[i - 1].tvd) / 2 + data[i - 1].tvd;
      }
    }

    // case when md and tvd not present for last sensor
    if (!this.isMdTvdPresent(data[data.length - 1])){
      const pos = data.length - 2;
      data[data.length - 1].md = (this.configObj.md  - data[pos].md) / 2 + data[pos].md;
      data[data.length - 1].tvd = (this.configObj.tvd  - data[pos].tvd) / 2 + data[pos].tvd;
    }
    return data;
  }

  // check md tvd present for all sensor
  isAllSensorMdTvdPresent(data){
    for (let i = 0; i < data.length; i++){
      if (!this.isMdTvdPresent(data[i])) {
        return false;
      }
    }
    return true;
  }

  // check md tvd not present for all sensor
  isAllSensorMdTvdNotPresent(data){
    for (let i = 0; i < data.length; i++){
      if (this.isMdTvdPresent(data[i])) {
        return false;
      }
    }
    return true;
  }

  // check md tvd present for one  sensor
  isMdTvdPresent(sensor){
    const obj = sensor;
    if ((obj.tvd === '' || obj.tvd === null || obj.tvd === undefined) || (
      obj.md === '' || obj.md === null || obj.md === undefined)) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Create a list of sensor object with simplified property list.
   * @param sensorRawData
   */
  getDataForSchematicFormat(sensorRawData) {
    const sensorList = [];

    _.map(sensorRawData, (sensor) => {
      if (sensor.response && sensor.response.dataSetProperties && sensor.response.dataSetProperties.length > 0 && sensor.response.dataSetProperties[0].data &&
        sensor.response.dataSetProperties[0].data && sensor.response.dataSetProperties[0].data.length > 0) {
          let sensorData = sensor.response.dataSetProperties[0];

        const mesaureDepth = sensorData.data.find((o) => o.variable === 'MeasuredDepth');
        const trueVerticalDepth = sensorData.data.find((o) => o.variable === 'TrueVerticalDepth');
        const gaugeName = sensorData.record;
        const deviceName = sensorData.run.split('_')[1];

        sensorList.push({
          name: this.uidWell, //sensor.response.dataSetProperties[0].uidWell, //response from the backend does not have uid so using the local uid
          sensorPath: sensorData.sensorPath,
          md: mesaureDepth['value'],
          tvd:  trueVerticalDepth['value'],
          mdUnit: mesaureDepth['uom'],
          tvdUnit:  trueVerticalDepth['uom'],
          alert: false,
          selected: this.isSensorSelected(sensor),
          gauge: `${deviceName}_${gaugeName}`
        });
      }
    });
    return sensorList;
  }

  //get the gaugeDevice name combination to be shown in the schematics screen
  getGaugeDeviceName(gauge){
    let combinedName = gauge.GaugeName;
    let gaugeName = combinedName.split(new RegExp('[_.]', 'g')).pop().toUpperCase();
    let gaugeNameSplit = combinedName.split('.', 2);
    let deviceName = gaugeNameSplit.length > 0 ? gaugeNameSplit[1].toUpperCase():"";
    return `${deviceName}_${gaugeName}`;
  }

  getDataFormattedForSchematic(wellHierarchy) {
    let sensorList = [];

    let WellId = wellHierarchy.WellId;
    _.map(wellHierarchy.Logs, (log) => {

      if (log.Gauges){
        let gauges = log.Gauges;

        for(let i=0; i< gauges.length; i++){
          let measuredDepth = gauges[i].MD ? this.conversionService.convertDepth(this.converterData.depthUnit, gauges[i].MD.Value) : null;
          let measuredDepthUOM = gauges[i].MD ? this.converterData.depthUnit ? this.converterData.depthUnit :gauges[i].MD.Uom : null;
          let totalVerticalDepth = gauges[i].MD ? this.conversionService.convertDepth(this.converterData.depthUnit, gauges[i].TVD.Value): null;
          let totalVerticalDepthUOM = gauges[i].MD ? this.converterData.depthUnit ? this.converterData.depthUnit: gauges[i].TVD.Uom : null;
          let gaugeDeviceName = this.getGaugeDeviceName(gauges[i]);
          let gaugeId = gauges[i].GaugeId;
          let logSensors = gauges[i].Sensors;

          const globallySelectedSensors =  _.filter(this.sensors, { checked: true });
          let isSelected = false;
          for (let j = 0; j < globallySelectedSensors.length; j++) {
            if (isSelected) {
              break;
            }
            _.filter(logSensors, (sensor) => {
              if (globallySelectedSensors[j].sensorName === sensor.sensorId) {
                isSelected = true;
                return true;
              };
              return false;
            });
          }

          if(measuredDepth){
            sensorList.push({
              name: WellId, //sensor.response.dataSetProperties[0].uidWell, //response from the backend does not have uid so using the local uid
              // sensorPath: sensor.response.dataSetProperties[0].sensorPath,
              md: measuredDepth,
              tvd:  totalVerticalDepth,
              mdUnit: measuredDepthUOM,
              tvdUnit:  totalVerticalDepthUOM,
              alert: false,
              selected: isSelected,
              gauge: gaugeDeviceName,
              gaugeId: gaugeId
            });
          }
        }
      }
    });
    sensorList = _.uniqBy(sensorList, 'gaugeId');
    sensorList = _.sortBy(sensorList,
      [function(o: any) { return o.md; }]);
    return sensorList;
  }

  /**
   * Check if the sensor is in global selection list
   * @param sen
   */
  isSensorSelected(sen) {
    const globallySelectedSensors =  _.filter(this.sensors, { checked: true });
    const gaugeName = sen.response.dataSetProperties[0].record;

    return  _.some(globallySelectedSensors, (sensor) => {
      return _.toUpper(sensor.sensorName.split(new RegExp('[_.]', 'g')).pop().replace(/pressure|temperature|pres|temp/gi, '')) === _.toUpper(gaugeName); // To-do : Dynamic filtering based on config file
    });

  }

  /**
   * Prepares sensor requests objects which is used to query sensor data
   * @param srs
   */
  getSensorRequestObjects(srs) {
    const requestData = _.map(srs , (sensor) => {
      const name = sensor.sensorOriginalName;
      const sensorSplit = name.split(new RegExp('[_.]', 'g'));
      const runName = this.getRunName(name);
      let rec = '';

      if (sensorSplit.length >= 6) {
        rec = sensorSplit[3];
      } else {
        rec = sensorSplit.pop().split(/pressure|temperature|pres|temp/gi)[0]; // To-do: Dynamic filtering based on config file
      }

      return {
        uidWell: this.selectedWell.uidWell,
        DataType: 'property',
        run: _.toUpper(runName),
        record: _.toUpper(rec),
        description: 'A'
      };
    });

    var result = requestData.filter(function (rec){
      var key = `${rec.run}_${rec.record}`;
      if (!this[key]){
        this[key] = true;
        return true;
      }
    }, Object.create(null));

    return this.getSorted(result);
  }

  /**
   * This create the backword compatobility with all the devices
   * 1) W1_XPIO2_A_GAUGE11Press
   * 2) W1_XPIO2_GAUGE11Pressure
   * 3) W1_xp_IO2_GAUGE12Temp
   *
   * Return run from sensor name
   */

  getRunName(sensorName) {
    const nameSplit = sensorName.split(new RegExp('[_.]', 'g'));
    const runName = nameSplit;

    if (runName.length >= 6) {
      return runName[0] + '_' + runName[1];
    } else {
      if (nameSplit.length > 2 && nameSplit[nameSplit.length - 2].toUpperCase() === 'A') {
        return runName.splice(0, nameSplit.length - 2).join('_');
      } else {
        runName.pop();
        return runName.join('_');
      }
    }
  }

  /**
   * This is not required if the data comes in correct format with description
   * Return description from sensor name
   */
  getDescription(nameSplit) {
    if (_.includes(nameSplit[2], 'Temp') || _.includes(nameSplit[2], 'Pres')) {
      return 'A';
    }
    else {
      return nameSplit[2];
    }
  }

  /**
   * Sorts on gauge name
   */
  getSorted(unsorted) {
    return  unsorted.sort((a, b) => a.run.localeCompare(b.run, undefined, {numeric: true}));
  }

  /**
   * Utility function for updating global wells
   * @param wells
   */
  updateGlobalSelectedWell(wells) {
    this.globalSelectedWells = [];
    _.forEach(wells, (well) => {
      if (well.checked) {
        this.globalSelectedWells.push(well);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Function called on change of well drop down
   */
  onWellChange() {
    this.showMessage = false;
    this.showErrorMessage = false;
    this.showWaitIndicator = true;
    this.context.clearRect(0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
    if (this.selectedWell) {
      this.getWellBoreData(this.selectedWell);
    }
  }

  /**
   * Draw actual schematic of a well
   */
  createVerticalSchematics(sensorData) {
    this.isVertical = true;
    this.configObj.leftSpace = this.myCanvas.nativeElement.width / 2 - this.configObj.totalWidth / 2;
    this.verticalConversionFactor = this.configObj.canvasHeight / (this.configObj.md);
    this.configObj.innerPipeLength = this.verticalConversionFactor * this.configObj.md;
    const centerPoint = this.configObj.totalWidth / 2 + this.configObj.leftSpace;
    const outerPipeDiameter = this.configObj.innerPipeDiameter + 30;

    // Code to draw the outerPipe with border
    this.context.beginPath();
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = outerPipeDiameter + 2;
    this.context.moveTo(centerPoint, this.configObj.topSpace);
    this.context.lineTo(centerPoint, this.configObj.innerPipeLength + this.configObj.topSpace + 15);
    this.context.stroke();

    this.context.strokeStyle = '#DAD8D9';
    this.context.lineWidth = outerPipeDiameter;
    this.context.stroke();

    // Code to draw the innerPipe with border
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = this.configObj.innerPipeDiameter + 2;
    this.context.stroke();

    this.context.strokeStyle = '#333333';
    this.context.lineWidth = this.configObj.innerPipeDiameter;
    this.context.stroke();
    this.count = 0;
    this.drawHead();
    this.drawAllSensors(sensorData); //draw all sensors
  }

  createHorizontalSchematics(sensorData){
    this.isVertical = false;
    this.configObj.leftSpace = 50;
    this.kickOffPoint = this.getKickOffPoint(sensorData);
    this.horizontalConversionFactor = this.configObj.canvasWidth / (this.configObj.md - this.kickOffPoint);
    this.configObj.horizontalPipeLength = this.horizontalConversionFactor * (this.configObj.md - this.kickOffPoint); //horizontal pipe length of horizontal well
    const centerPoint = this.configObj.totalWidth / 2 + this.configObj.leftSpace;
    const outerPipeDiameter = this.configObj.innerPipeDiameter + 30;

    if (this.kickOffPoint && Number(this.kickOffPoint) !== 0) {
    this.verticalOfHorizontalConversionFactor = this.configObj.canvasHeight / this.kickOffPoint;
    }
    else {
      this.verticalOfHorizontalConversionFactor = 0;
    }
    if (this.verticalOfHorizontalConversionFactor === 0) { //Kick off point of the well is 0. To position the schematics properly.
      this.configObj.topSpace = 150;
    }
    this.configObj.verticalPipeLength = this.verticalOfHorizontalConversionFactor * this.kickOffPoint; //vertical pipe length of horizontal well
    const verticalHeight = this.configObj.verticalPipeLength / 2 + this.configObj.topSpace;
    // Code to draw the outerPipe with border
    this.context.beginPath();
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = outerPipeDiameter + 2;
    //draw the vertical part of horizontal well
    this.context.moveTo(centerPoint, this.configObj.topSpace);
    this.context.lineTo(centerPoint, verticalHeight + 15);  // add 15 to place the sensor according to the pipe length
    this.context.stroke();

    //draw the arc of
    this.context.arcTo(centerPoint, 15 + verticalHeight + outerPipeDiameter / 2, centerPoint + outerPipeDiameter, 15 + verticalHeight + outerPipeDiameter / 2, 30); // add 15 to place the sensor according to the pipe length
    this.context.stroke();

    //draw the horizontal part of horizontal well
    this.context.moveTo(centerPoint + outerPipeDiameter / 2,  15 + verticalHeight + outerPipeDiameter / 2); // add 15 to place the sensor according to the pipe length
    this.context.lineTo(centerPoint +  outerPipeDiameter / 2 + this.configObj.horizontalPipeLength,  15 + verticalHeight + outerPipeDiameter / 2); // add 15 to place the sensor according to the pipe length
    this.context.stroke();
    this.context.strokeStyle = '#DAD8D9';
    this.context.lineWidth = outerPipeDiameter;
    this.context.stroke();

    // Code to draw the innerPipe with border
    this.context.strokeStyle = '#000000';
    this.context.lineWidth = this.configObj.innerPipeDiameter + 2;
    this.context.stroke();

    this.context.strokeStyle = '#333333';
    this.context.lineWidth = this.configObj.innerPipeDiameter;
    this.context.stroke();
    this.count = 0;
    this.drawHead();
    this.drawAllSensors(sensorData); //draw all sensors
  }

  // To find the kick off point
  getKickOffPoint(sensorData): any {
    sensorData = sensorData.sort((gauge1, gauge2) => {
      return Number(gauge1.md) - Number(gauge2.md);
    });
    for (let i = 0; i < sensorData.length; i++) {
      if (Number(sensorData[i].md) > Number(sensorData[i].tvd) && i === 0) {
        return sensorData[i].tvd; //if only horizontal gauges are present
      } else if (Number(sensorData[i].md) > Number(sensorData[i].tvd) && i > 0)  {
        return Number((sensorData[i - 1].tvd)); //if horizontal and vertical gauges are present
      }
    }
    return Number(sensorData[sensorData.length - 1].tvd); //if only vertical gauges are present
  }

  drawAllSensors(sensorData) {

    sensorData = _.sortBy(sensorData,
      [function(o: any) { return Number(o.md); }]);

    for (let i = 0; i < sensorData.length; i++) {
      sensorData[i].count =  i+1;
      this.drawSensor(sensorData[i]);
    }
    for (let i = 0; i < sensorData.length; i++) { //draw gauges for which alert has been added but not triggered
      const gaugeName = sensorData[i]['name'];
      const gaugeId = sensorData[i]['gauge'];
      if (this.sensorColors[gaugeName] !== undefined && this.sensorColors[gaugeName][gaugeId] === 'green') {
        this.drawSensor(sensorData[i]);
      }
    }
    for (let i = 0; i < sensorData.length; i++) {// draw gauges for which alert has been added and has been triggered
      const gaugeName = sensorData[i]['name'];
      const gaugeId = sensorData[i]['gauge'];
      if (this.sensorColors[gaugeName] !== undefined && this.sensorColors[gaugeName][gaugeId] === 'red') {
        this.drawSensor(sensorData[i]);
      }
    }
    //redrawing selected sensors for hover effect
    for (let i = 0; i < sensorData.length; i++) {
    if (this.hoverGauge) {
        if (sensorData[i]['gauge'] === this.hoverGauge.toUpperCase()) {
          this.drawSensor(sensorData[i]);
        }
      }
    }
  }
  /* istanbul ignore next */
  // tslint:disable-next-line:cyclomatic-complexity
  drawSensor(sensor) {
    this.horizontalConversionFactor = this.configObj.canvasWidth / (this.configObj.md - this.kickOffPoint); // horizontal part of horizontal well
    this.verticalConversionFactor = this.configObj.canvasHeight / (this.configObj.tvd); //vertical well

    if (!(isFinite(this.horizontalConversionFactor) && isFinite(this.verticalConversionFactor))){
      return false;
    }
    if (this.kickOffPoint && Number(this.kickOffPoint) !== 0) {
      this.verticalOfHorizontalConversionFactor = this.configObj.canvasHeight / this.kickOffPoint; //vertical part of horizontal well
      }
      else {
        this.verticalOfHorizontalConversionFactor = 0;
      }
    this.count = sensor.count;
    const innerRectangleLeftPos = ((this.configObj.totalWidth / 2) - (this.configObj.innerPipeDiameter / 2)) + this.configObj.leftSpace;
    const centerPoint = this.configObj.totalWidth / 2 + this.configObj.leftSpace;
    let sensorHeight = 15;
    let sensorWidth = this.configObj.innerPipeDiameter;
    const wellName = sensor.name;

    let topPos = (sensor.md * this.verticalConversionFactor) + this.configObj.topSpace; //vertical well
    if (sensor.md === sensor.tvd && !this.isVertical) {
      topPos = (sensor.md * this.verticalOfHorizontalConversionFactor) / 2 + this.configObj.topSpace; //for sensors drawn on vertical part of horizontal well
    }
    let sensorColor = '#414141';

    if (this.sensorColors && this.sensorColors[wellName] && this.sensorColors[wellName][sensor.gauge]) {
      sensorColor = (this.sensorColorCodes[this.sensorColors[wellName][sensor.gauge.toUpperCase()]]) ? this.sensorColorCodes[this.sensorColors[wellName][sensor.gauge.toUpperCase()]] : '#414141';
    }
    if (this.hoverGauge) {
      sensorColor = this.getSensorColor(sensor, sensorColor);
    }

    const outerPipeDiameter = this.configObj.innerPipeDiameter + 25;
    const grd = this.context.createRadialGradient(centerPoint, topPos + (sensorHeight / 2), 0.5, centerPoint, topPos + (sensorHeight / 2), 15);
    grd.addColorStop(0, 'white');
    grd.addColorStop(0.7, sensorColor);
    grd.addColorStop(1, 'black');

    this.context.lineWidth = 0.5;
    this.context.fillStyle = grd;
    this.context.font = '10px Arial';

    if (Number(sensor.md) > Number(sensor.tvd)) {
      sensorHeight = this.configObj.innerPipeDiameter;
      sensorWidth = 15;
      const leftPos = (sensor.md - this.kickOffPoint) * this.horizontalConversionFactor + centerPoint +  outerPipeDiameter / 2;
      topPos = this.configObj.verticalPipeLength / 2 + this.configObj.topSpace + 30; //horizontal well

      let mdTxt = ' ' + sensor.md + ' ' + sensor.mdUnit;
      if (this.isSimulatedData) {
        mdTxt = '';
      }
      const grd = this.context.createRadialGradient(leftPos + (sensorWidth / 2), topPos + (sensorHeight / 2), 0.5, leftPos + (sensorWidth / 2), topPos + (sensorHeight / 2), 15);
      grd.addColorStop(0, 'white');
      grd.addColorStop(0.7, sensorColor);
      grd.addColorStop(1, 'black');

      this.context.fillStyle = grd;
      this.context.fillRect(leftPos,   topPos, sensorWidth, this.configObj.innerPipeDiameter);
      this.context.strokeRect(leftPos, topPos, sensorWidth, this.configObj.innerPipeDiameter);

      this.context.fillRect(leftPos +  (sensorWidth / 2) - 2.5, topPos - (sensorWidth / 2) + 2.5 , 5, this.configObj.innerPipeDiameter + 10);
      this.context.strokeRect(leftPos + (sensorWidth / 2) - 2.5, topPos - (sensorWidth / 2) + 2.5,  5, this.configObj.innerPipeDiameter + 10);
      //To place each Node and MD text of the sensors at different levels to avoid overlapping in the canvas
      let additionalLength = this.count % 3 === 1 ? 50 : this.count % 3 === 2 ? 100 : 0;
      if (this.count % 2 === 1) {
        if (sensor.selected) {
          // this.context.fillStyle = '#0404ef';
          this.context.font = 'bold 11px Arial';
          this.context.fillText(mdTxt + ' ]', leftPos + 5, topPos - (outerPipeDiameter / 1.5)-additionalLength);
        } else {
          this.context.fillText(mdTxt, leftPos + 5, topPos - (outerPipeDiameter / 1.5)-additionalLength);
        }
        this.context.beginPath();
        this.context.moveTo(leftPos ,  topPos);
        this.context.lineTo(leftPos , topPos - (outerPipeDiameter)-additionalLength);
        this.context.stroke();
      } else {
        if (sensor.selected) {
          // this.context.fillStyle = '#0404ef';
          this.context.font = 'bold 11px Arial';
          this.context.fillText(mdTxt + ' ]', leftPos + 5,  topPos + 100 + additionalLength );
        } else {
          this.context.fillText(mdTxt, leftPos + 5,  topPos + 100 + additionalLength);
        }
        this.context.beginPath();
        this.context.moveTo(leftPos ,  topPos);
        this.context.lineTo(leftPos , topPos + 100 + additionalLength);
        this.context.stroke();

      }
    } else{
      sensorHeight = 15;
      let mdTxt = ' ' + sensor.md + ' ' + sensor.mdUnit;
      if (this.isSimulatedData) {
        mdTxt = '';
      }
      this.context.fillRect(innerRectangleLeftPos, topPos, this.configObj.innerPipeDiameter, sensorHeight);
      this.context.strokeRect(innerRectangleLeftPos, topPos, this.configObj.innerPipeDiameter, sensorHeight);

      this.context.fillRect(innerRectangleLeftPos - 5, topPos + (sensorHeight / 2) - 2.5, this.configObj.innerPipeDiameter + 10, 5);
      this.context.strokeRect(innerRectangleLeftPos - 5, topPos + (sensorHeight / 2) - 2.5, this.configObj.innerPipeDiameter + 10, 5);

      let textXPos =  innerRectangleLeftPos + outerPipeDiameter;
      let lineXPos = innerRectangleLeftPos + outerPipeDiameter;
      if (this.count % 2 === 1) {
        textXPos = this.configObj.leftSpace;
        lineXPos = this.configObj.leftSpace;
      } else {
        lineXPos = textXPos + 100;
      }
      if (sensor.selected) {
        this.context.font = 'bold 11px Arial';
        this.context.fillText('[ ' + mdTxt + ' ]', textXPos, topPos);
      } else {
        this.context.fillText( mdTxt,  textXPos, topPos);
      }
      this.context.beginPath();
      this.context.moveTo(centerPoint,  topPos + sensorHeight / 2);
      this.context.lineTo(lineXPos , topPos + sensorHeight / 2);
      this.context.stroke();
    }
  }

  getSensorHeightWidth(sensor){
    if (this.hoverGauge && this.formattedSensorData) {
      if (_.toUpper(sensor.gauge) === _.toUpper(this.hoverGauge)){
        return 20;
      }
      return 15;
    }
    return 15;
  }

  getSensorColor(sensor, sensorColor){
    if (this.hoverGauge && this.formattedSensorData) {
      if (_.toUpper(sensor.gauge) === _.toUpper(this.hoverGauge)){
        return '#66cbea';
      }
      return sensorColor;
    }
    return sensorColor;
  }
  /**
   * Draw head part of the schematic
   */
  drawHead() {
    // draw static legend
    this.drawLegend();

    // Horizontal Line
    this.context.beginPath();
    this.context.lineWidth = 10;
    const startPoint = this.configObj.leftSpace + (this.configObj.totalWidth / 2) - this.configObj.innerPipeDiameter - 5;
    const endPoint = this.configObj.leftSpace + (this.configObj.totalWidth / 2) + this.configObj.innerPipeDiameter + 5;
    this.context.moveTo(startPoint, this.configObj.topSpace);
    this.context.lineTo(endPoint, this.configObj.topSpace);

    this.context.moveTo(startPoint - 20, this.configObj.topSpace - 10);
    this.context.lineTo(endPoint + 20, this.configObj.topSpace - 10);
    this.context.stroke();
  }
}
